import _ from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'
import * as Analytics from '../../lib/analytics'
import * as AuthServiceAPI from '../../lib/auth'
import { ToggleModel } from '../../lib/model/model-toggle'
import { createDuplicateLabel } from '../../lib/model/model-utils'
import * as ConfigExperimentsAPI from '../../lib/config-experiments'
import { MetricsFunnelNodeViewDirectiveInstance, MetricsFunnelNodeGridViewModelFactory } from './metrics-grid.directive'
import { isTotalRow, deserializeViews } from './metrics-utils'
import { MetricsPageTabViewModel } from './metrics-tab-view-model'
import { purifyURL } from '../../lib/dom/html'

module = angular.module '42.controllers.metrics', [
    '42.modules.libs.utils'
]
module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'metrics'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when(route.url, route)


module.service 'MetricsHierarchy', ($q, CONFIG, Hierarchy, HourProperty) ->
    fetch: -> $q.all([
            Hierarchy.fetch().then(({pebbles}) -> pebbles)
            HourProperty.fetch()
        ]).then ([hierarchy, hourProperty]) ->
            hierarchy.push(hourProperty) if hourProperty
            hierarchy.forEach (x) -> x.sort ?= do ->
                field = CONFIG.defaults?.metrics?.sortBy or 'net_sales'
                return {field, order:-1}
            return hierarchy


module.controller 'MetricsController', ($q, $rootScope, $scope, MetricsHierarchy, MetricsKPIsService, MetricsViewsAPI) ->
    refresh = -> $q.all([
        MetricsHierarchy.fetch(),
        MetricsKPIsService.fetch($rootScope.query),
        AuthServiceAPI.getOrganization(),
        ConfigExperimentsAPI.fetch(),
        (new MetricsViewsAPI).then (api) -> api.get().then((data) -> data?.views or {})
    ]).then ([properties, metrics, organization, experiments, views]) ->
        smartGroups = if experiments.segmentsInMetricsPage then $rootScope.smartGroupsService else null
        panel = do ->
            {isOpen} = views.panel or {isOpen: true}
            return new ToggleModel(isOpen)
        $scope.model = { properties, metrics, organization, smartGroups, views: panel: panel }

    $rootScope.$on '$destroy', \
    $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        return refresh() if not $rootScope.hierarchySelectModel
        $rootScope.$on '$destroy', \
        $rootScope.$watch 'hierarchySelectModel.view.selected', (selected) ->
            return if not selected
            refresh()


module.directive('metricsFunnelNodeView', MetricsFunnelNodeViewDirectiveInstance())
module.factory('MetricsFunnelNodeGridViewModel', MetricsFunnelNodeGridViewModelFactory())

module.constant 'METRICS_TABLE_ROW_HEIGHT', 85

module.factory 'MetricsFunnel', (MetricsFunnelNode) -> class MetricsFunnel

    constructor: ({@properties, metrics, selectedMetrics, views, @actions, node}) ->
        selectedMetrics ?= []
        @root = new MetricsFunnelNode({@properties, property: @properties[0], level:0})
        @nodes = []
        @nodes.push(@root)
        @views = views ? {}
        @node = node ? @nodes[@nodes.length-1]
        # @selected = selected ?
        #     node: node
        #     property: node.property
        @metrics =
            available: metrics
            selected:  @_normalizeSelectedMetrics(metrics, selectedMetrics)

    resetNodes: ->
        @root = new MetricsFunnelNode({@properties, property: @properties[0], level:0})
        @nodes = [@root]

    select: (node, value, sort) ->
        node.updateProperty(node.propertyDraft)
        properties = _.filter node.properties, (x) -> x.id isnt node.property.id
        property = do ->
            prevIndex = node.properties.map((x)->x.id).indexOf(node.property.id)
            return properties[prevIndex] or properties[0]
        return if not property
        property.sort = sort ? property.sort
        node.value = value
        @nodes = @nodes[0..node.level]
        @nodes.push new MetricsFunnelNode
            properties: properties
            property:   property
            level:      node.level+1
            parent:     node

        @actions.save(@node)
        return @

    updateGridConfigColumnWidth: (columnsResized) ->
        @views.columns ?= {}
        keys = Object.keys(columnsResized)
        keys.forEach((key) => @views.columns[key] = columnsResized[key])
        @actions.save(@node) if @node and @node.property

    selectFunnelValue: (value) ->
        @select(@node, value, @node.property.sort)

    selectNode: (node) ->
        @node.resetProperties()
        @node = do =>
            return node if @node.level isnt node.level
            return @nodes.find((nodeItem) -> nodeItem.level is (node.level + 1)) or node

    updateSort: (sortedColumns) ->
        sort = if sortedColumns.length > 0 then sortedColumns else undefined
        @node.setSort(sort)
        @nodes.forEach((node) -> node.setSort(sort))
        @actions.save(@node)

    updateColumnSortOrder: (columnSortOrder) ->
        if @metrics.selected
            @metrics.selected = _.sortBy(@metrics.selected, (item) -> columnSortOrder.indexOf(item.field))
            @actions.save(@node)

    selectProperty: (property) ->
        if @node
            sort = @node.property?.sort
            property.sort = sort ? property.sort
            @node.property = property
            @node.propertyDraft = _.cloneDeep(property)
            @actions.save(@node)

    updatePropertyDraft: (property) ->
        if @node
            sort = @node.property?.sort
            property.sort = sort ? property.sort
            @node.updatePropertyDraft(property)

    serialize: ->
        properties: @nodes?.map((x) -> x.serialize())
        metrics: @metrics.selected.map((x) -> x.field)
        views: _.cloneDeep(@views)

    selectMetrics: (metrics) ->
        @metrics.selected = @_normalizeSelectedMetrics(@metrics.available, metrics)
        @actions.save(@node)
        return @

    @deserialize: ({properties, metrics, state, actions}) ->
        return null if not _.isObject(state)
        metricsFunnel = new MetricsFunnel({
            properties: properties,
            metrics: metrics,
            selectedMetrics: state.metrics,
            views: state.views,
            actions: actions,
        })
        metricsFunnel.nodes = do ->
            prevNode = null
            nodes = []
            level = 0
            for x in state.properties
                currNode = MetricsFunnelNode.deserialize(x, properties, prevNode, level)
                nodes.push(currNode) if currNode
                prevNode = currNode
                level++
            return metricsFunnel.nodes if nodes.length is 0
            return nodes
        return metricsFunnel

    _normalizeSelectedMetrics: (availableMetrics, selectedMetrics) ->
        metricsByField = _.keyBy availableMetrics, (x) -> x.field
        selectedMetrics = _.compact selectedMetrics.map (x) -> metricsByField[x.field or x]
        selectedMetricsHeaderGroupOrder = selectedMetrics.reduce ((result, metric) ->
            result[metric.headerGroup] ?= Object.keys(result).length + 1
            return result
        ), {}
        metricHeaderGroupOrder = availableMetrics.reduce ((result, metric) ->
            result[metric.headerGroup] ?= do ->
                selectedOrder = selectedMetricsHeaderGroupOrder[metric.headerGroup]
                return selectedOrder if not _.isUndefined(selectedOrder)
                return (availableMetrics.length + Object.keys(result).length) + 1
            return result
        ), {}
        selectedMetricOrder = selectedMetrics.reduce ((result, metric, index) ->
            result[metric.field] = index
            return result
        ), {}
        return _.sortBy selectedMetrics, [
            ((x) -> metricHeaderGroupOrder[x.headerGroup])
            ((x) -> selectedMetricOrder[x.field])
        ]


module.factory 'MetricsFunnelNode', ($rootScope, $q, QueryServiceAPI, QueryServiceExport, QueryMetrics, MetricsFunnelNodeGridViewModelAssociatedColumns, CONFIG) -> class MetricsFunnelNode

    constructor: ({@property, @properties, @level, @parent, @value} = {}) ->
        @propertyDraft = _.cloneDeep(@property)
        @level ?= 0

    _fetchMetricsFunnelNode: (query, queryId) ->

        QueryServiceAPI().then (api) => api.query[queryId](query).then (data) =>
            return data if query.type is 'xlsx'
            result = data.reduce ((result, row) ->
                collection = if isTotalRow(row) then 'total' else 'rows'
                result[collection].push(row)
                return result
            ), {rows:[], total:[]}
            result.total.push([{property0:'$total'}]) if result.total.length is 0
            return result if not @property.type
            parseFn = switch @property.type
                when 'numeric' then (x) ->
                    parsed = parseInt(x)
                    return x if _.isNaN(parsed)
                    return parsed
                else (x) -> x
            result.rows.forEach (row) ->
                row.property0 = parseFn(row.property0)
            return result


    fetch: (rootQuery, queryId = 'metricsFunnel') ->
        query = _.cloneDeep @toQuery(rootQuery)
        promise = if queryId is 'metricsFunnel' then QueryMetrics.fetch() else $q.when(undefined)
        promise.then (metrics) =>
            query.options.metrics = metrics.map((metric) -> metric.field) if Array.isArray(metrics)
            return @_fetchMetricsFunnelNode(query, queryId)

    getDrilldownProperties: ->
        current = @parent
        result = []
        while current
            result.push current.property.id
            current = current.parent
        return result

    export: (rootQuery = {}, type = "xlsx", tabName) ->
        rootQuery = _.cloneDeep(rootQuery)
        rootQuery.type = type
        tabName ?= @property.id.replace(/\./g, '-')
        filename = "42-metrics-#{tabName}.#{type}"
        @fetch(rootQuery, 'frye__productClassification').then(QueryServiceExport.downloadAs(filename))

    getSort: ->
        sort = _.cloneDeep(@propertyDraft.sort ? @property.sort)
        return undefined if not sort
        return sort

    setSort: (sort) ->
        return undefined if not sort
        @property.sort = _.cloneDeep(sort)
        @propertyDraft.sort = _.cloneDeep(sort)

    updateProperty: (property) ->
        @property = _.cloneDeep(property)
        @propertyDraft = _.cloneDeep(property)

    updatePropertyDraft: (property) ->
        propertyDraft = _.cloneDeep(property)
        propertyDraft.sort = _.cloneDeep(@property.sort) ? property.sort
        @propertyDraft = propertyDraft

    resetProperties: (property) ->
        @propertyDraft = _.cloneDeep(@property)

    toQuery: (rootQuery = {}) ->
        property = (@propertyDraft ? @property).id
        query = _.cloneDeep(rootQuery)
        timestamp = query.filters?.transactions?.timestamp

        query.filters = {}

        # FIXME: I am not sure if this is doing anything?
        if $rootScope.Experiments.segmentsInMetricsPage
            query.filters = query.filters or {}

        query.options ?= {}
        query.modifiers ?= {}
        query.options.sort = @getSort()
        # TODO
        # Hack to provide backward compability with new Metrics Page feature - Save Tab State
        # query.options.sort = query.options.sort[0] if Array.isArray(query.options.sort)
        # else always array
        query.options.sort = [query.options.sort] if query.options.sort and not Array.isArray(query.options.sort)
        query.options.property = property
        query.options.associatedProperties ?= @_getAssociatedProperties()
        query.filters.transactions = {timestamp} if timestamp
        current = @.parent
        return query if not current
        query.filters.items ?= {}
        while current
            property = current.property.id
            [collection, field] = property.split('.')
            query.filters[collection] ?= {}
            query.filters[collection][field] = current.value
            current = current.parent
        return query

    _getAssociatedProperties: ->
        {columns} = MetricsFunnelNodeGridViewModelAssociatedColumns(@)
        return [] if not columns
        return _.uniq columns.map (x) -> x.field.replace(/^item_/, 'items.')

    serialize: ->
        property: @property.id
        sort:     @property.sort
        value:    @value

    @deserialize: (data, properties, parent, level) ->
        return null if not data
        property = _.find(properties, (x) -> x.id is data.property)
        return null if not property
        property = _.cloneDeep(property)
        property.sort = data.sort
        node = new MetricsFunnelNode({property, properties, level, parent, value:data.value})
        node = @hydrateProperties(node, properties)
        return node

    @hydrateProperties: (metricsFunnelNode, properties) ->
        if _.isNil(metricsFunnelNode.parent)
            metricsFunnelNode.properties = properties
            return metricsFunnelNode
        else
            parent = metricsFunnelNode.parent
            properties = _.filter(parent.properties, (val) -> parent.property.id isnt val.id)
            metricsFunnelNode.properties = properties
            return metricsFunnelNode


module.service 'CellClasses', ->
    percent = (value) ->
        return 'percent-negative' if value < 0
        return 'percent-positive' if value > 0
        return null
    'percent': (cell) ->
        percent(cell.value)
    'percent-inverted': (cell) ->
        percent(cell.value * -1)


module.service 'MetricsGridCellRenderers', ($filter, METRICS_TABLE_ROW_HEIGHT) ->
    MARGIN_HEIGHT = 6
    blank: () -> '<span class="cell-blank-value">—</span>'
    anchor: (row) ->
        anchorElement = document.createElement('a')
        anchorElement.href = purifyURL(row.value)
        anchorElement.setAttribute("target", "_blank")
        anchorElement.textContent = row.value

        return anchorElement.outerHTML
    metric: (row) ->
        return row.value if not row.colDef.cellFilter
        [filter, args...] = row.colDef.cellFilter.split(':')
        return $filter(filter)(row.value, args...)
    image: (row) ->
        return '' if not row.item_image

        imgElement = document.createElement('img')
        imgElement.setAttribute("height", "#{METRICS_TABLE_ROW_HEIGHT - MARGIN_HEIGHT}")
        imgElement.setAttribute("src", purifyURL(row.item_image))
        return imgElement.outerHTML

module.factory 'MetricsGridDefaultCellRenderer', (MetricsGridCellRenderers) -> (row) ->
    return MetricsGridCellRenderers.blank(row) if _.isNil(row.value)
    return MetricsGridCellRenderers.anchor(row) if row.colDef.field is 'item_image'
    return MetricsGridCellRenderers.metric(row)


# This is a super hack to get the metrics supported by the dataset
module.service 'MetricsKPIsService', ($q, $rootScope, CONFIG, Utils, QueryServiceAPI, QueryMetrics, CellClasses) -> fetch: (query) ->
    query = Utils.copy(query or {})
    query.filters = {transactions:query.filters.transactions}
    query.options = {property:"stores.aggregate"}

    $q.all([
        QueryMetrics.fetch(),
        AuthServiceAPI.getOrganization()
    ]).then ([metrics, org]) ->
        # We don't want to show the percent parent metrics on metrics page because there's only one level.
        if org not in ['allsaints_dev', 'allsaints-new', 'allsaints']
            metrics = metrics.filter (metric) -> not do ->
                /^percentage_parent_/.test(metric.field) or \
                /^growth_percentage_parent_/.test(metric.field)

        metrics = metrics.map (x) ->
            x._cellClass = x.cellClass
            x.cellClass = CellClasses[x.cellClass] or x.cellClass
            return x

        filterMetric = (kpis) ->
            return kpis.filter (kpi) ->
                return false if not ($rootScope.flags.showBudget or $rootScope.flags.showBudgets) and kpi.includes('budget')
                return true

        getMetricsKPIs = ->
            availableKpis = Utils.copy(metrics)
            enabledKpis = do ->
                userEnabledKpis = $rootScope.accessControl?.kpis
                orgEnabledKpis = CONFIG.views?.metrics?.kpis
                return if not orgEnabledKpis
                return _.compact(_.uniq(_.concat(orgEnabledKpis, userEnabledKpis)))
            return availableKpis if not enabledKpis
            enabledKpis = filterMetric(enabledKpis) if org in ['allsaints_dev', 'allsaints-new', 'allsaints']
            availableKpisIndex = _.keyBy availableKpis, (x) -> x.field
            return _.compact enabledKpis.map (kpi) -> availableKpisIndex[kpi]

        allMetricsFactory = ->
            kpis = getMetricsKPIs()
            kpis.copy = -> allMetricsFactory()
            return kpis

        metricsFactory = (data) ->
            return allMetricsFactory() if not data?[0]
            kpis = getMetricsKPIs()
            supportKpisIndex = Object.keys(data[0]).reduce ((result, field) ->
                result[field] = true
                return result
            ), {}
            result = kpis.reduce ((result, kpi) ->
                result.push(kpi) if supportKpisIndex[kpi.field]
                return result
            ), []
            result.copy = -> metricsFactory(data)
            return result

        return allMetricsFactory()


module.factory 'MetricsFunnelNodeGridViewModelAssociatedColumns', () -> (node, org) ->
    associatedColumns = []
    width = undefined
    selectedDrilldownProperties = node.getDrilldownProperties()

    switch org
        when 'joesjeans', 'joesjeans_dtc'
            switch node.property.id
                when 'items.class'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                    ]
                when 'items.subclass'
                    width = 200
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Department', field:'item_department', width:120} if 'items.department' in selectedDrilldownProperties
                        {headerName:'Class', field:'item_class', width:90} if 'items.class' in selectedDrilldownProperties
                    ]
                when 'items.pattern'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class', field:'item_class', width:90}
                        {headerName:'Subclass', field:'item_subclass', width:100}
                        {headerName:'DCS Code', field:'item_dcs_code', width:100, pinned:false}
                    ]
                when 'items.style'
                    width = 80
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'DCS Code',   field:'item_dcs_code', width:100, pinned:false}
                        {headerName:'Body',       field:'item_pattern', width:150, pinned:false}
                    ]
                when 'items.material'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Department', field:'item_department', width:120}
                        {headerName:'Class',      field:'item_class', width:90}
                        {headerName:'Subclass',   field:'item_subclass', width:100}
                        {headerName:'DCS Code',   field:'item_dcs_code', width:100, pinned:false}
                        {headerName:'Body',       field:'item_pattern', width:150, pinned:false}
                        {headerName:'Season',     field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',       field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
        when 'rebeccaminkoff', 'rebeccaminkoff_dtc'
            switch node.property.id
                when 'items.name'
                    width = 150
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Division', field:'item_division_name', width:150}
                        {headerName:'Category', field:'item_product_category', width:100}
                        {headerName:'Group',    field:'item_product_group', width:150}
                        {headerName:'Season',   field:'item_season', width:125, pinned:false}
                    ]
                when 'items.sku'
                    width = 200
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Division', field:'item_division_name', width:150}
                        {headerName:'Category', field:'item_product_category', width:100}
                        {headerName:'Group',    field:'item_product_group', width:150}
                        {headerName:'Name',     field:'item_name', width:150}
                        {headerName:'Season',   field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',     field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.upc'
                    width = 120
                    associatedColumns = associatedColumns.concat [
                        {headerName:'Division', field:'item_division_name', width:150}
                        {headerName:'Category', field:'item_product_category', width:100}
                        {headerName:'Name',     field:'item_name', width:150}
                        {headerName:'Group',    field:'item_product_group', width:150}
                        {headerName:'SKU',      field:'item_sku', width:150, pinned:false}
                        {headerName:'Season',   field:'item_season', width:125, pinned:false}
                        {headerName:'MSRP',     field:'item_msrp', width:125, cellFilter:'money:0', pinned:false}
                    ]
                when 'items.color'
                    width = 80
                    associatedColumns = associatedColumns.concat _.compact [
                        {headerName:'Division', field:'item_division_name', width:100} if 'items.division_name' in selectedDrilldownProperties
                        {headerName:'Category', field:'item_category', width:100} if 'items.category' in selectedDrilldownProperties
                        {headerName:'Name',     field:'item_name',  width:150} if 'items.name' in selectedDrilldownProperties
                    ]

    return {columns:associatedColumns, width}


module.directive 'breadcrumbHeader', ($rootScope) ->
    restrict: 'E'
    scope:
        isEmpty: '='
        onReset: '&'
    replace: true,
    template: \
    """
        <header class="metrics-funnel-breadcrumb-header">
            <div class="info">
                <h1>Selected Filters</h1>
                <button class="reset" ng-if="!isEmpty" ng-click="onReset()">reset</button>
            </div>
            <div ng-if="!collapse && isEmpty && isSegmentsEnabled" class="help-text-container">
                <p class="help-text">
                    <i class="icon-help-circled"></i>
                    select a
                    <span class="pellet">group by</span>
                    to see values for that attribute, click on a row's
                    <span class="cell-value no-border">
                        <button>value</button>
                    </span>
                    to drill down
                </p>
            </div>
        </header>

    """
    link: (scope, element) ->
        scope.$on '$destroy', $rootScope.$watch 'Experiments.segmentsInMetricsPage', (flag) ->
            scope.isSegmentsEnabled = flag

        HELP_TEXT_CONTAINER_WIDTH = 568
        scope.collapse = false

        verifyHelpTextSize = ->
            infoContainer = element[0].getElementsByClassName('info')[0]
            elementWidth = element.width() or 0
            infoContainerWidth = infoContainer.offsetWidth or 0

            scope.collapse = (elementWidth - infoContainerWidth - 15) <= HELP_TEXT_CONTAINER_WIDTH

        resizeObserver = new ResizeObserver () -> verifyHelpTextSize()
        resizeObserver.observe(element[0])

        scope.$watch('isEmpty', -> verifyHelpTextSize())
        scope.$on '$destroy', -> resizeObserver.disconnect()


module.directive 'metricsFunnelBreadcrumb', ($rootScope) ->
    restrict: 'E'
    scope:
        model: '='
        export:   '='
        segment:  '='
        panelToggle: '='
        onReset:  '&'
    # replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="funnel-state">
            <breadcrumb-header is-empty="isEmpty" on-reset="onReset()"></breadcrumb-header>
            <main class="ui-pellets">
                <ul>
                    <li ng-if="node.value"
                        class="funnel-node"
                        ng-repeat="node in pellets"
                        ng-class="{'funnel-node-segment': node.segment, 'funnel-node-selected': model.node == node}"
                    >
                        <div class="ui-pellet" ng-class="{active: model.node == node}" ng-click="selectNode(node)">
                            <span class="ui-pellet-property">{{ node.property.label }}</span>
                            <span ng-if="node.value" class="ui-pellet-value">{{ node.value }}</span>
                        </div>
                    </li>
                </ul>
                <p class="help-text old" ng-if="!collapse && isEmpty && !isSegmentsEnabled">
                    <i class="icon-help-circled"></i>
                    click on a
                    <span class="ui-pellet"><span>property</span></span>
                    to change how the rows are rolled up, click on a row's
                    <span class="cell-value no-border">value</span>
                    to drill down
                </p>
            </main>
        </section>
        <div class="collapsed-header-actions">
            <button-export on-click="onExportClick()" text="exportButtonText"></button-export>
            <div class="toggle-header-button">
                <div class="show-header-button" ng-click="panelToggle.show($event)">
                    <span>Show Panel</span>
                    <i class="icon-down-open"></i>
                </div>
            </div>
        </div>
        <section class="available-properties">
            <div class="properties-container">
                <header>
                    <h1>Group By</h1>
                </header>
                <ul class="ui-pellets">
                    <li class="ui-pellet available-property"
                        ng-repeat="property in model.node.properties"
                        ng-click="selectProperty(property)"
                        ng-class="{selected: model.node.propertyDraft.id == property.id}">
                        <span>{{ property.label }}</span>
                    </li>
                </ul>
            </div>
            <div class="buttons-holder">
                <ui-metric-selector-tree ng-if="metricSelectModel" model="metricSelectModel"></ui-metric-selector-tree>
                <button-export class="export-panel-button" on-click="onExportClick()"></button-export>
                <div class="header-hide-button" ng-click="panelToggle.hide($event)">
                    <span>Hide Panel</span>
                    <i class="icon-up-open"></i>
                </div>
            </div>
        </section>
    </article>
    """
    link: (scope) ->
        scope.exportButtonText = 'export'
        scope.segmentItem = { value: null, property: { label: 'segment' }, segment: true }
        scope.pellets = []
        scope.isEmpty = true

        scope.onExportClick = ->
            Analytics.track(Analytics.EVENTS.USER_EXPORTED_PAGE_METRICS)
            scope.export()

        isEmpty = ->
            return true if scope.pellets.length is 0
            return true if not scope.model?.nodes
            return _.isUndefined(scope.model?.nodes[0].value)

        ## REMOVE CODE WHEN SEGMENTS TOGGLE IS REMOVED `enableSegmentsInMetricsPage`
        do ->
            HELP_TEXT_CONTAINER_WIDTH = 568
            helpTextObserver = null
            verifyHelpTextSize = null
            unsubSegmentChange = null
            unsubSegments = \
            $rootScope.$watch 'Experiments.segmentsInMetricsPage', (segmentsInMetricsPage) ->
                scope.isSegmentsEnabled = segmentsInMetricsPage
                unsubSegmentChange?()

                if segmentsInMetricsPage
                    unsubSegmentChange = scope.$watch('segment', (segment) ->
                        if segment
                            if scope.segmentItem.value isnt segment
                                scope.segmentItem.value = segment ? null
                                scope.pellets = [scope.segmentItem].concat(scope.model?.nodes or [])
                        else
                            if scope.pellets?.length > 0 and scope.pellets[0].segment
                                scope.pellets = scope.model?.nodes or []

                        scope.isEmpty = isEmpty()
                    )
                    return

                verifyHelpTextSize ?= do ->
                    fn = ->
                        return if $rootScope.Experiments?.segmentsInMetricsPage
                        infoContainer = document.getElementsByClassName('funnel-state')?[0]
                        if infoContainer
                            infoContainerWidth = infoContainer.offsetWidth or 0
                            scope.collapse = (infoContainerWidth - 15) <= HELP_TEXT_CONTAINER_WIDTH
                    scope.$watch('isEmpty', -> fn())
                    return fn
                helpTextObserver ?= do ->
                    observer = new ResizeObserver () -> verifyHelpTextSize()
                    observer.observe(document.getElementsByClassName('view-metrics')[0])
                    return observer
                return

            scope.collapse = false
            scope.$on '$destroy', ->
                unsubSegments()
                helpTextObserver?.disconnect()

        scope.$watch 'model', ((funnel, prev) ->
            return if not scope.model or not funnel?.metrics

            isNewMetrics = not _.isEqual(funnel.metrics.selected, prev?.metrics.selected) or not scope.metricSelectModel
            return if not isNewMetrics

            {selected: selectedMetrics, available} = _.cloneDeep(scope.model?.metrics or {})
            selected = selectedMetrics.map((metric) -> metric.field)
            scope.metricSelectModel = {
                selected,
                available,
                options: { addAllMetrics: true, hideCategorize: !Boolean($rootScope.Experiments.metricsCategorization) },
                onChange: (metrics) -> scope.model.selectMetrics(metrics)
            }
        ), true

        scope.selectNode = (node) ->
            return if node.segment
            scope.model.selectNode(node)

        scope.selectProperty = (property) ->
            { node, nodes } = scope.model

            if node is nodes[nodes.length - 1]
                scope.model.selectProperty(property)
            else
                scope.model.updatePropertyDraft(property)

        scope.$watch 'model.nodes', (nodes) ->
            scope.pellets = do ->
                return [scope.segmentItem].concat(nodes) if scope.segment
                return nodes or []
            scope.isEmpty = isEmpty()



module.factory 'MetricsViewStorageAPI', ($rootScope, StorageAPI) -> -> StorageAPI do ->
    hierarchyKey = $rootScope.hierarchySelectModel?.view?.selected?.id
    prefix = "metrics.views.v1"
    return prefix if not hierarchyKey
    return "#{prefix}.#{hierarchyKey}"


module.factory 'MetricsViewsAPI', ($q, MetricsViewStorageAPI) -> ->
    (new MetricsViewStorageAPI).then (api) -> api.get().then (initial) ->
        state = initial
        get: ->
            return $q.when _.cloneDeep(state)
        put: (data) ->
            state = _.cloneDeep(data)
            api.put(state)
            return $q.when(state)


module.factory 'ColumnViewsAPI', ($q, StorageAPI) -> ->
    StorageAPI('metrics.column-views').then (api) -> api.get().then (initial) ->
        state = initial
        get: ->
            return $q.when _.cloneDeep(state)
        put: (data) ->
            state = _.cloneDeep(data)
            api.put(state)
            return $q.when(state)

module.factory 'TabStateViewModel', (Utils, MetricsViewsAPI, ColumnViewsAPI, MetricsFunnel) -> class TabStateViewModel

    constructor: (@properties, @metrics) ->
        @tabs = []
        @selectedTab = null
        @propertyById = @properties.reduce ((dict, property) ->
            dict[property.id] = property
            return dict
        ), {}

    reset: =>
        @tabs = []
        @addNewTab()

    addNewTab: =>
        return @addTab("New View", @metrics.map((x) -> x.field))

    resetTab: (tab) ->
        tab.funnel.resetNodes()
        tab.funnel.node = tab.funnel.nodes[tab.funnel.nodes.length-1]
        @saveDrillDownState(tab.funnel.node)

    addTab: (name, selectedMetrics) =>
        funnel = new MetricsFunnel(
            properties: @properties,
            metrics: @metrics,
            selectedMetrics: selectedMetrics
            actions:
                save: (selectedState) => @saveDrillDownState(selectedState)
        )
        tabObj = new MetricsPageTabViewModel({
            name,
            funnel
            # node: funnel.nodes[funnel.nodes.length-1]
            # actions:
            #     save: (selectedState) => @saveDrillDownState(selectedState)
        })
        @tabs.push(tabObj)
        @selectedTab = @tabs[@tabs.length-1]
        @saveDrillDownState(@selectedTab.funnel.node)

    duplicated: =>
        funnelState = @selectedTab.funnel.serialize()
        funnel = MetricsFunnel.deserialize(
            properties: @properties,
            metrics: @metrics,
            state: funnelState,
            actions:
                save: (selectedState) => @saveDrillDownState(selectedState)
        )
        tabObj = new MetricsPageTabViewModel({
            name: createDuplicateLabel(@selectedTab.name),
            funnel: funnel
        })

        index  = @tabs.indexOf(@selectedTab)
        index ?= @tabs.length - 1
        @tabs = Utils.insertAt(@tabs, (index+1), tabObj)
        @selectedTab = tabObj
        @saveDrillDownState(@selectedTab.funnel.node)

    removeTab: (id) =>
        return if not id
        index = _.findIndex @tabs, (x) -> x.id is id
        @tabs = @tabs.filter((x) -> x.id isnt id)
        @selectedTab = @tabs[Math.min(@tabs.length-1, index)]
        @saveDrillDownState(@selectedTab.funnel.node)

    reorderTabs: (oldIndex, newIndex) =>
        @tabs = Utils.move(@tabs, oldIndex, newIndex)
        @selectedTab = @tabs[newIndex]
        @saveDrillDownState(@selectedTab.funnel.node)

    saveDrillDownState: _.debounce((selected) ->
        return if not selected
        tabData =
            available: @tabs.map (x) ->
                {properties, metrics, views} = x.funnel.serialize()
                return {id:x.id, name:x.name, properties, metrics, views: views}
            selected: @selectedTab.id
        (new MetricsViewsAPI).then (api) ->
            api.get().then (data) ->
                data.available = tabData.available
                data.selected = tabData.selected
                return api.put(data)
    , 300)


    loadState: ->

        loadTabs = =>
            (new MetricsViewsAPI).then (api) => api.get().then (drillDownResult) =>
                return false if (drillDownResult or []).length is 0
                tabs = drillDownResult.available
                selectedTabId = drillDownResult.selected
                return false if (tabs or []).length is 0
                @tabs = tabs.map (x) =>
                    funnel = MetricsFunnel.deserialize({
                        properties: @properties,
                        metrics: @metrics,
                        state: x,
                        views: deserializeViews(x.views)
                        actions:
                            save: (selectedState) => @saveDrillDownState(selectedState)
                    })

                    tab = new MetricsPageTabViewModel({
                        id: x.id,
                        name: x.name,
                        funnel,
                    })
                    return tab

                @selectedTab = _.find(@tabs, (x) -> x.id is selectedTabId) or @tabs[0]

                return true

        loadOldColumnTabs = =>
            (new ColumnViewsAPI).then (api) => api.get().then (data) =>
                return false if (data or []).length is 0
                data.forEach (state) => @addTab(state.label, state.columns)
                return true

        loadTabs().then (hasNewTabs) =>
            return if hasNewTabs
            loadOldColumnTabs().then (hasOldTabs) => return @reset() if not hasOldTabs
        .then =>
            @selectedTab ?= @tabs[0]

module.directive 'metricsView', ($timeout, MetricsViewsAPI, TabStateViewModel) ->
    restrict: 'E'
    scope:
        model: '='
        organization: '='
    replace: true
    template: \
    """
    <section class="view view-metrics">
        <div class="loadable" ng-class="{loading:!model}"></div>
        <tabs-with-menu
            tabs="tabStateViewModel.tabs"
            added="addNewTab"
            removed="tabStateViewModel.removeTab"
            selected="tabStateViewModel.selectedTab"
            dragged="tabStateViewModel.reorderTabs"
            duplicated="tabStateViewModel.duplicated"
            >
        </tabs-with-menu>
        <main>
            <metrics-funnel-breadcrumb
                ng-if="tabStateViewModel.selectedTab.funnel"
                export="export"
                model="tabStateViewModel.selectedTab.funnel"
                on-reset="resetTab()"
                panel-toggle="panelToggle"
                segment="selectedSegment">
            </metrics-funnel-breadcrumb>
            <metrics-funnel-node-view
                ng-if="tabStateViewModel.selectedTab.funnel"
                tab-name="tabStateViewModel.selectedTab.name"
                model="tabStateViewModel.selectedTab.funnel"
                export-setter="exportSetter"
                organization="model.organizationId">
            </metrics-funnel-node-view>
        </main>
    </section>
    """
    link: (scope, element) ->
        scope.exportButtonText = 'export'
        $element = $(element)

        updatePanel = () ->
            return if not scope.panel
            isOpen = scope.panel.isOpen

            $headerActionsPanel = $element.find('.collapsed-header-actions')

            if not isOpen
                $headerActionsPanel.addClass('show')
            else
                $headerActionsPanel.removeClass('show')

            $tableElement = element.find('.metrics-funnel-node')

            if scope.panel.isOpen and $tableElement
                headerFunnelElementHeight = element.find('.metrics-funnel-breadcrumb .funnel-state')?.outerHeight() or 0
                headerPropertiesElementHeight = element.find('.metrics-funnel-breadcrumb .available-properties')?.height() or 0
                topHeight = headerFunnelElementHeight + headerPropertiesElementHeight

                $tableElement.css
                    height: "calc(100% - #{topHeight}px)"
                    top: "#{topHeight}px"
            else
                $headerFunnelElement = element.find('.metrics-funnel-breadcrumb .funnel-state')
                $tabsElement = element.find('.ui-tabs-with-menu')

                topHeight = ($tabsElement?.height() or 0) + ($headerFunnelElement?.height() or 0)

                $tableElement.css
                    height: "calc(100% - #{topHeight}px)"
                    top: "#{topHeight}px"

            return savePanelState()

        savePanelState = ->
            panelView = scope.panel.serialize()
            (new MetricsViewsAPI).then (api) -> api.get().then (data) ->
                data ?= {}
                data.views ?= {}
                data.views.panel = panelView
                api.put(data)

        transitionsTimeoutCancelFn = null

        initTransition = ->
            transitionsTimeoutCancelFn?()

            headerElement = $element.find('.metrics-funnel-node')
            headerElement.addClass('transitions')
            headerTransitionTimeout = $timeout((-> headerElement.removeClass('transitions')), 1000)

            headerActionsPanel = $element.find('.collapsed-header-actions')
            headerActionsPanel.addClass('transitions')
            headerActionsTimeout = $timeout((-> headerActionsPanel.removeClass('transitions')), 500)

            transitionsTimeoutCancelFn = ->
                $timeout.cancel(headerTransitionTimeout)
                $timeout.cancel(headerActionsTimeout)

        resizeObserver = null

        initResizeObserver = ->
            $headerElement = element.find('.metrics-funnel-breadcrumb')
            if $headerElement?.length
                resizeObserver?.disconnect()
                resizeObserver = new ResizeObserver (-> updatePanel())
                resizeObserver.observe($headerElement[0])

        scope.panelToggle =
            hide: ->
                scope.panel.close()
                initTransition()
                updatePanel()
            show: ->
                scope.panel.open()
                initTransition()
                updatePanel()

        scope.resetTab = () ->
            scope.tabStateViewModel.resetTab(scope.tabStateViewModel.selectedTab)

        scope.exportSetter = (exportFunc) ->
            scope.export = exportFunc

        scope.selectedSegment = null

        scope.addNewTab = ->
            Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_METRICS)
            scope.tabStateViewModel?.addNewTab()

        refreshSegmentName = (name) ->
            return if not scope.model?.smartGroups
            name ?= scope.model?.smartGroups?.selected?.model?.name
            scope.selectedSegment = name

        scope.$watch 'model.smartGroups.selected.model.name', (name) ->
            refreshSegmentName(name)

        scope.$watch 'model', (model) ->
            return if not model
            scope.panel = model.views.panel
            scope.tabStateViewModel = new TabStateViewModel(scope.model.properties, scope.model.metrics)
            scope.tabStateViewModel.loadState()
            refreshSegmentName()

        scope.$watch 'tabStateViewModel.selectedTab.funnel.nodes', ((nodes) ->
            return if not nodes
            node = nodes[nodes.length-1]
            scope.tabStateViewModel.selectedTab.funnel.selectNode(node)
        )

        save = ->
            return if not scope.tabStateViewModel?.selectedTab?.funnel
            scope.tabStateViewModel.saveDrillDownState(scope.tabStateViewModel.selectedTab.funnel.node)

        updatePanel()

        scope.$watch('tabStateViewModel.selectedTab', (->
            save()
            if scope.panel
                updatePanel()
                initResizeObserver()
        ), true)

        scope.$on '$destroy', -> resizeObserver?.disconnect()
