import _ from 'lodash';
import { CallbackDataParams as eChartsParams } from 'echarts/types/dist/shared';
import type { I42CustomSeriesOption, ISeriesData } from './chart-echarts-wrapper.directive';
import { isObject } from '../../lib/utils';
import { ECHARTS_GROUPED_SERIES_COLOR } from './chart-echarts.config';

const MAX_ELEMENTS_PER_TOOLTIP_COLUMN = 12;

export const DEFAULT_PIE_VALUE_THRESHOLD = 0.02;
export const DEFAULT_PIE_COUNT_THRESHOLD = 10;

// TODO - Study best slice strategy.
// At the moment, `maxNumberOfSlices` and `groupSlicesThresholdValue` are "OR" conditions
// When both options enabled, `maxNumberOfSlices` prevails

export interface IGroupSeriesOptions {
    maxNumberOfSlices?: number;
    groupSlicesThresholdValue?: number;
}

export const validateThresholds = (value: unknown, defaultValue: number) => {
    if (typeof value !== 'number') return defaultValue;
    if (value <= 0) return defaultValue;
    return value;
};

function getSlicesTotalValue(slices: { value: undefined | null | number }[]): number {
    return slices.reduce((acc, item) => acc + Math.abs(item.value ?? 0), 0);
}

export interface I42GroupedDisplayOptions {
    maxNumberOfSlices: number;
    groupSlicesThresholdValue: number;
}
export function normalizeGroupedSeriesDisplayOptions(options: unknown): I42GroupedDisplayOptions {
    const chartOptions = isObject(options) ? options : {};
    return {
        maxNumberOfSlices: validateThresholds(chartOptions.maxNumberOfSlices, DEFAULT_PIE_COUNT_THRESHOLD),
        groupSlicesThresholdValue: validateThresholds(
            chartOptions.groupSlicesThresholdValue,
            DEFAULT_PIE_VALUE_THRESHOLD,
        ),
    };
}

export function shouldGroupSeries(serie: I42CustomSeriesOption, options: I42GroupedDisplayOptions): boolean {
    const slices = serie.data ?? [];
    if (options.maxNumberOfSlices > 0) return slices.length > options.maxNumberOfSlices;
    const totalValue = getSlicesTotalValue(slices);
    return !slices.every(item => Math.abs(item.value / totalValue) >= options.groupSlicesThresholdValue);
}

export const hasGroupSeries = (serie: undefined | I42CustomSeriesOption): boolean => {
    const data = Array.isArray(serie?.data) ? serie.data : null;
    if (!data) return false;
    return Boolean(data[data.length - 1]?.groupedSeries);
};

export function buildEchartTooltipListItem(options: {
    name: string;
    value: string | null;
    color?: string | null;
    percent: string | null;
    isNegative?: boolean;
}): string {
    const seriesValueContainerEl = document.createElement('div');
    seriesValueContainerEl.classList.add('tooltip-series');

    if (typeof options.percent === 'string') {
        const el = document.createElement('span');
        el.classList.add('tooltip-series-label');
        el.innerText = options.percent;
        seriesValueContainerEl.appendChild(el);
    }

    const seriesValueEl = (() => {
        const el = document.createElement('span');
        el.classList.add('tooltip-series-value');
        if (options.isNegative) el.classList.add('negative');
        const value = (options.value ?? '').length === 0 ? null : options.value;
        // if (value === null) el.classList.add('blank');
        el.innerText = value ?? '—';
        return el;
    })();
    seriesValueContainerEl.appendChild(seriesValueEl);

    const seriesTitleEl = (() => {
        const el = document.createElement('h1');
        el.classList.add('tooltip-header');
        if (options.color) el.style.color = options.color;
        el.innerText = options.name;
        return el;
    })();

    return `
    ${seriesTitleEl.outerHTML}
    ${seriesValueContainerEl.outerHTML}
    `;
}

export type I42EChartsTooltipParams = Omit<eChartsParams, 'data' | 'color'> & { data: ISeriesData; color: string };

export function buildEchartTooltip($filter: angular.IFilterService, params: I42EChartsTooltipParams) {
    const isGrouped = Boolean(params.data.groupedSeries);
    const buildFn = isGrouped ? buildEchartTooltipFromGroupedSeries : buildEchartTooltipFromNormalSeries;
    return buildFn($filter, params);
}

function buildEchartTooltipFromNormalSeries($filter: angular.IFilterService, params: I42EChartsTooltipParams) {
    const isNegative = params.data.isNegative ?? false;
    const name = params.name;

    // This is done because Echarts does no represent a slice with a negative value.
    // So, the value is changed to positive to be able to occupy space and then the tooltip shows the negative sign.
    const rawValue = (() => {
        if (!_.isNumber(params.value) || !isNegative) return params.value;
        return params.value <= 0 ? params.value : params.value * -1;
    })();
    const value = $filter('metric')(rawValue, params.data.metric);
    const color = isNegative ? params.borderColor ?? params.color : params.color;
    const percent = typeof params.percent === 'number' ? $filter('percent')(params.percent / 100) : null;
    const html = buildEchartTooltipListItem({ color, name, value, isNegative, percent });
    return `<div class='tooltip'>${html}</div>`;
}

function buildEchartTooltipFromGroupedSeries($filter: angular.IFilterService, params: I42EChartsTooltipParams) {
    const { groupedSeries, metric } = params.data;
    const columns = _.chunk(groupedSeries, MAX_ELEMENTS_PER_TOOLTIP_COLUMN);
    const tooltipColumnsHTML = columns.map(column => {
        const tooltipItemsHTML = column.map(group => {
            const value = $filter('metric')(group.value, metric);
            const percent = typeof group.percent === 'number' ? $filter('percent')(group.percent) : null;
            return buildEchartTooltipListItem({
                color: ECHARTS_GROUPED_SERIES_COLOR,
                name: group.name,
                isNegative: group.isNegative ?? false,
                value,
                percent,
            });
        });
        return `<div class="tooltip-column">${tooltipItemsHTML.join('')}</div>`;
    });
    return `<div class='tooltip columns'>${tooltipColumnsHTML.join('')}</div>`;
}
