interface CloseButtonDirectiveScope<T = unknown> extends angular.IScope {
    onClickAction: ($event: Event) => void;
    text?: unknown;
    onClose: () => void;
}
export const ButtonCloseDirective = () => [
    function ButtonCloseDirective(): angular.IDirective<CloseButtonDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                onClose: '=',
                text: '=',
            },
            replace: true,
            template: `
                <div class="button-close" ng-click="onClickAction($event)">
                    <i class="icon-cancel"></i>
                    <i class="icon-cancel-circled button-close"></i>
                </div>
            `,
            link: function CloseButtonLink(scope) {
                scope.onClickAction = event => {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    scope.onClose();
                };
            },
        };
    },
];

const buttonCloseModule = angular
    .module('42.components.button-close', [])
    .directive('buttonClose', ButtonCloseDirective());

export default buttonCloseModule;
