import _ from 'lodash';
import { ICellRendererComp, ICellRendererParams } from '@ag-grid-community/core';
import type { IPropertyDefinition } from '../../../lib/config-hierarchy';
import type { IMetricDefinition } from '../../../lib/types';
import {
    InfoItemCellRenderer,
    InfoItemCellRendererInitParams,
    ItemCellRenderer,
    ItemCellRendererInitParams,
    renderItemMetrics,
} from '../../../components/item-cell-renderer';

export interface IItemActionToggleModel {
    active: boolean;
    toggle: () => void;
    serialize: () => { active: boolean };
}

export interface IItemsSortBy {
    group: string;
    id: string;
    label: string;
}

export interface IGridActionsModel {
    dropdown: boolean;
    editMode: boolean;
    fillNameIfNeeded: () => void;
    id: string;
    name: string;
    parentSave: () => void;
    save: () => void;
    selected?: {
        groupBy: IPropertyDefinition;
        itemsExtraInfo: { column?: string; id: string }[];
        itemsGroupBy: IPropertyDefinition;
        itemsLimitBy: number;
        itemsSortBy: IItemsSortBy;
        metrics?: string[];
    };
    values?: {
        groupBy: IPropertyDefinition[];
        hierarchyIndex: {
            groupBy: Record<string, IPropertyDefinition>;
            indexGroupBy: Record<string, IPropertyDefinition>;
        };
        itemsGroupBy: Record<string, IPropertyDefinition>;
        itemsLimitBy: number[];
        itemsSortBy: IItemsSortBy[];
        metrics: IMetricDefinition[];
        metricsByField: Record<string, IMetricDefinition>;
    };
    views?: {
        images?: boolean;
        metrics: IItemActionToggleModel;
        panel: IItemActionToggleModel;
    };
}

type ICustomDataCellRendererParams = Record<string, string | number> & {
    items: Record<string, string | number>[];
};

export function GridItemCellRendererFactory($filter: angular.IFilterService, actionsModel: IGridActionsModel) {
    type GridItemCellRendererInitParams = ItemCellRendererInitParams &
        Omit<ICellRendererParams, 'data'> & { data: ICustomDataCellRendererParams };

    return class GridItemCellRenderer extends ItemCellRenderer implements ICellRendererComp {
        readonly actionsModel: IGridActionsModel;

        constructor() {
            super($filter);
            this.actionsModel = actionsModel;
        }

        public override init(params: GridItemCellRendererInitParams) {
            const columnId = params.column?.getColId();
            const colIndex = params.columnApi.getAllColumns()?.findIndex(col => col.getColId() === columnId);
            if (typeof colIndex !== 'number' || colIndex === -1) {
                throw new Error('[Grid Cell Renderer] Column index not found!');
            }
            const data = params.data;
            const item = data.items[colIndex - 1];

            super.init({
                item,
                label: item?.property1,
                imagesEnabled: actionsModel.views?.images ?? true,
                availableMetrics: actionsModel.values?.metricsByField,
                selectedMetrics: actionsModel.selected?.metrics,
                selectedExtraProperties: actionsModel.selected?.itemsExtraInfo,
            });
        }

        refresh(): boolean {
            return true;
        }

        toggleImages(imagesEnabled: boolean): void {
            if (!this.imageConfig || !this.eGuiImageElement || this.imagesEnabled === imagesEnabled) {
                return;
            }

            if (imagesEnabled) {
                this.eGuiImageElement.classList.add(this.imageConfig.klass);
                this.eGuiImageElement.style.backgroundImage = `url('${this.imageConfig.value}')`;
            } else {
                this.eGuiImageElement.classList.remove(this.imageConfig.klass);
                this.eGuiImageElement.style.backgroundImage = 'none';
            }

            this.imagesEnabled = imagesEnabled;
        }
    };
}

export function GridInfoCellRendererFactory($filter: angular.IFilterService, actionsModel: IGridActionsModel) {
    type GridInfoItemCellRendererInitParams = InfoItemCellRendererInitParams &
        Omit<ICellRendererParams, 'data'> & { data: ICustomDataCellRendererParams };

    return class GridInfoItemCellRenderer extends InfoItemCellRenderer implements ICellRendererComp {
        constructor() {
            super($filter);
        }

        override init(params: GridInfoItemCellRendererInitParams) {
            const item = params.data;
            super.init({
                item,
                label: item.property0,
                availableMetrics: actionsModel.values?.metricsByField ?? {},
                selectedMetrics: actionsModel.selected?.metrics ?? [],
            });
        }

        refresh(): boolean {
            return true;
        }

        update(metrics: string[] = []): void {
            this.metrics = metrics.map((metric: string) => _.find(this.availableMetrics, ['field', metric]));
            this.metricsHTML = renderItemMetrics($filter, this.metrics, (metric: IMetricDefinition) => {
                return metric.field && this.item && this.item[metric.field] !== undefined
                    ? this.item[metric.field]
                    : '';
            });

            if (this.eGuiMetricsElement) {
                const nodes = new DOMParser().parseFromString(this.metricsHTML, 'text/html').body.childNodes;
                this.eGuiMetricsElement.replaceChildren(...Array.from(nodes));
            }
        }
    };
}
