import _ from 'lodash'
import moment from 'moment'
import Utils from '../lib/utils'
import { CustomError } from '../lib/utils'
import { isRequestAbortedError } from '../lib/api'
import * as Auth from '../lib/auth'
import * as ConfigFlags from '../lib/config-flags'
import * as ConfigExperimentsAPI from '../lib/config-experiments'
import * as QueryBuilder from '../lib/query/query-builder'
import { Browser, LoadingStatus, DatabaseStatusMonitor } from '../lib/services'
import { DatabaseDescriptorsService } from '../lib/config-database-descriptors'
import DashboardModelsModule from './main/dashboard-models'
import DashboardNavbarModule from './main/main-navbar'
import DashboardStatusBarModule from './main/status-message-bar-module.coffee'
import { StorageDatepickerCalendarId } from '../modules/datepicker/datepicker-storage-selected-calendar'

module = angular.module '42.controllers.main', [
    '42.modules',
    DashboardModelsModule.name,
    DashboardNavbarModule.name,
    DashboardStatusBarModule.name
]

class DataLoadingInProgressError extends CustomError
    constructor: ->
        super('Data loading is in progress')


class UserSuspendedError extends CustomError
    constructor: ->
        super('User is suspended')

class DashboardSuspendedError extends CustomError
    constructor: ->
        super('Dashboard is suspended')

class DashboardInitError extends CustomError
    constructor: ->
        super('Dashboard could not be initialized')


SIMULATE_USER_SUSPENDED = false and Browser.isLocalhost()
SIMULATE_DASH_SUSPENDED = false and Browser.isLocalhost()
SIMULATE_DASH_NOT_READY = false and Browser.isLocalhost()


module.controller 'MainController',
($rootScope, $scope, $q, $timeout, $window, CONFIG, DashboardFilterModel, DashboardCalendarModel, DashboardCurrencyModel, DashboardMaxTimestampModel, DashboardSmartGroupsModel, Hierarchy, HierarchySelectModel, DashboardQuery, DashboardSidebarToggleModel, DashboardDatabaseStatus, StatusMessageBarModel, AccessControl) ->
    LoadingStatus.loading("Initializing Dashboard")

    $scope.organization = CONFIG.organization
    $scope.navbarModel = {expand: false}

    $rootScope.DashboardQuery = DashboardQuery
    $rootScope.DashboardSidebarToggleModel = DashboardSidebarToggleModel
    $rootScope.Browser = Browser

    $scope.loading = false
    $rootScope.initialized = false

    $scope.DashboardDatabaseStatus = DashboardDatabaseStatus

    DatabaseStatusMonitor.addEventListener 'databaseStatusChanged', (event) ->
        status = event.detail.status
        DashboardDatabaseStatus.set(status)
        StatusMessageBarModel.set(status)
        $rootScope.$digest()

    return $q.all([
        Auth.getUser(),
        Auth.getOrganization()
    ])
    .catch (error) ->
        analytics.track("error/app/initialization-failed/session", {error}) if not isRequestAbortedError(error)
        LoadingStatus.error(LoadingStatus.Messages.InitializationError())
        return $q.reject(error)
    .then ([user, organization]) ->
        $rootScope.organizationId = organization
        return {user, organization}
    .then (context) ->
        return $q.reject(new DashboardSuspendedError()) if (CONFIG.accessControl?.status is 'suspended') or SIMULATE_DASH_SUSPENDED
        return context
    .then (context) ->
        promises = []

        promises.push $q.when(ConfigFlags.fetchPageFlags()).then (pageFlags) ->
            try console.log("[PageFlags]:", JSON.stringify(pageFlags, null, 2))
            $scope.pages = pageFlags
            return

        promises.push $q.when(ConfigExperimentsAPI.fetch()).then (experiments) ->
            try console.log("[ConfigExperimentsAPI]:", JSON.stringify(experiments, null, 2))
            $rootScope.Experiments = experiments
            return

        promises.push $q.when(ConfigFlags.fetch()).then (flags) ->
            try console.log("[ConfigFlags]:", JSON.stringify(flags, null, 2))
            $rootScope.flags = flags ? {}
            return

        promises.push $q.when(AccessControl).then (accessControl) ->
            try console.log("[AccessControl]:", JSON.stringify(accessControl, null, 2))
            $rootScope.accessControl = accessControl
            isSuspended = accessControl?.status is 'suspended'
            return $q.reject(new UserSuspendedError()) if isSuspended or SIMULATE_USER_SUSPENDED
            return

        return $q.all(promises).then(() -> context)

    .then(({user, organization}) ->
        return $q.when(DatabaseStatusMonitor.start())
        .then((status) -> {user, organization, status})
        .catch (error) ->
            if not isRequestAbortedError(error)
                analytics.track("error/app/initialization-failed/status", {error})
                LoadingStatus.error(LoadingStatus.Messages.InitializationError(organization))
            return $q.reject(error)
    )

    .then ({user, organization, status}) ->
        return $q.reject(new DataLoadingInProgressError()) if status.isLoading or SIMULATE_DASH_NOT_READY
        return {user, organization, status}

    .then ({organization, status}) ->
        DashboardMaxTimestampModel.updateDashboard(status.latestTransactionTimestamp)

        calendarModelPromise = do ->
            $q.when(DashboardCalendarModel.fetch()).then (model) ->
                update = ->
                    StorageDatepickerCalendarId.set(model.getState().calendarId)
                    DashboardCalendarModel.updateDashboard(model)
                $rootScope.calendarModel = model
                $rootScope.$watch('calendarModel.state.ref', update)
                update()
                return model

        currencyModelPromise = do ->
            $q.when(DashboardCurrencyModel.fetch()).then (model) ->
                update = ->
                    model.save()
                    DashboardCurrencyModel.updateDashboard(model)
                $rootScope.currencyModel = model
                $rootScope.$watch('currencyModel.selected.id', update)
                update()
                return model

        hierarchySelectModelPromise = do ->
            $q.when(HierarchySelectModel.fetch()).then (model) ->
                $rootScope.hierarchySelectModel = model
                return model

        smartGroupsModelPromise = do ->
            $q.all([
                DashboardSmartGroupsModel.init()
                hierarchySelectModelPromise
            ]).then ([SmartGroupsModel, hierarchyModel]) ->
                $rootScope.SmartGroupsModel = SmartGroupsModel
                $rootScope.smartGroupsModel = do ->
                    return new SmartGroupsModel() if not hierarchyModel
                    return new SmartGroupsModel(hierarchyModel.getId(hierarchyModel.view.selected))
                return SmartGroupsModel

        # this is to catch any errors with the hierarchy before we start the dashboard
        hierarchyPromise = do ->
            hierarchySelectModelPromise.then (hierarchySelectModel) ->
                hierarchyId = hierarchySelectModel?.view?.selected?.id
                return Hierarchy.fetch(hierarchyId)

        return $q.all([
            smartGroupsModelPromise
            currencyModelPromise
            calendarModelPromise
            hierarchySelectModelPromise
            hierarchyPromise
        ]).catch (error) ->
            return $q.reject(error) if error instanceof UserSuspendedError
            analytics.track("error/app/initialization-failed", {error}) if not isRequestAbortedError(error)
            LoadingStatus.error(LoadingStatus.Messages.InitializationError(organization))
            return $q.reject(error)

    .then(([SmartGroupsModel, currencyModel, calendarModel, hierarchyModel]) ->

        LoadingStatus.loading("Applying finishing touches")

        if hierarchyModel
            $rootScope.$watch 'hierarchySelectModel.view.selected.id', (selected) ->
                return if not selected
                try $rootScope.smartGroupsModel?.view?.popup?.close()
                $rootScope.smartGroupsModel = new SmartGroupsModel(hierarchyModel.view.selected.id)
                doHierarchySmartGroupSwitchingAnimation($timeout)

        updateQueryFromSmartGroup = (filters) ->
            return if not filters
            updatedFilters = do ->
                filters = _.cloneDeep(filters)
                Object.keys(filters).forEach (k) -> delete filters[k] if _.isEmpty(filters[k])
                return filters
            DashboardFilterModel.updateDashboard(calendarModel, updatedFilters)

        $rootScope.$watch 'smartGroupsModel.view.groups.view.selected.model.query', (query) ->
            updateQueryFromSmartGroup(query.filters)

        # FIXME: This probably doesn't work anymore...
        #
        # I think the intent behind this function is that, when we get a latest transaction timestamp
        # from the StatusMonitor, we only refresh the dashboard when the user is looking at the current day
        onLatestTransactionTimestampChanged = (latestTransactionTimestamp) ->
            return if not latestTransactionTimestamp
            return if not $rootScope.initialized
            timerange = try QueryBuilder.QueryTimestampSelection.get(DashboardQuery.get())
            return if not timerange
            start = moment.utc(timerange.start)
            end   = moment.utc(timerange.end)
            # The assumption here is that, if our timerange is small, then we care about "realtime" updates...
            dayDifference = end.add(1, 'day').diff(start, 'days')
            return if dayDifference >= 7
            # Unclear why there is a delay here?
            delay = Math.round(Math.random() * 1000 * 5)
            $timeout (->
                DashboardMaxTimestampModel.updateDashboard(latestTransactionTimestamp)
            ), delay

        $scope.$watch 'DashboardDatabaseStatus.state.status.latestTransactionTimestamp', onLatestTransactionTimestampChanged
        $scope.$watch 'DashboardDatabaseStatus.state.status.isLoading', (currIsLoading, prevIsLoading) ->
            return if _.isNil(currIsLoading)
            prevIsLoading ?= false
            loadingIsDone  = prevIsLoading is true  and currIsLoading is false
            loadingStarted = prevIsLoading is false and currIsLoading is true
            if loadingStarted
                $rootScope.initialized = false
                analytics.track("loading-start", {status: DashboardDatabaseStatus.get()})
                LoadingStatus.loading(LoadingStatus.Messages.LoadingDataFromAppStarted())
                return
            if loadingIsDone
                delay = Math.round(Math.random() * 1000 * 60)
                $timeout((() -> $window.location.reload()), delay)

        $rootScope.queryState =
            previous:         undefined
            changeCounter:    0
            broadcastCounter: 0

        broadcastQueryChange = (query) ->
            query ?= DashboardQuery.get()
            query = _.cloneDeep(query)
            $rootScope.queryState.broadcastCounter += 1
            $rootScope.$broadcast('query.refresh', {query})

        $rootScope.$watch 'DashboardQuery.state', ->
            prev = $rootScope.queryState.previous

            query = DashboardQuery.get()
            query = DashboardCalendarModel.updateQuery(calendarModel, query)
            query = DashboardCurrencyModel.updateQuery(currencyModel, query)
            return if Utils.Object.hash(query) is Utils.Object.hash(prev)

            console.group("Query changed")
            console.log "previous:\n", if prev then JSON.stringify(prev, null, 2) else null
            console.log "current:\n", JSON.stringify(query, null, 2)
            console.groupEnd()

            $rootScope.queryState.changeCounter += 1
            $rootScope.queryState.previous = _.cloneDeep(query)
            $rootScope.query = query
            $rootScope.queryStr = try JSON.stringify(query, null, 2)
            broadcastQueryChange(query)

        $rootScope.initialized = true
        $rootScope.query = DashboardQuery.get()
        LoadingStatus.done()

    ).catch (error) ->

        if error instanceof DataLoadingInProgressError
            $rootScope.initialized = false
            LoadingStatus.loading(LoadingStatus.Messages.LoadingDataFromAppInit())
            $scope.$watch 'DashboardDatabaseStatus.state.status.isLoading', (currentIsLoading) ->
                return if SIMULATE_DASH_NOT_READY
                $window.location.reload() if currentIsLoading is false
            analytics.track("loading-start", {status: DashboardDatabaseStatus.get()})
            return

        if error instanceof UserSuspendedError
            $rootScope.initialized = false
            LoadingStatus.error(LoadingStatus.Messages.SuspendedUserMessage($rootScope.organizationId))
            return

        if error instanceof DashboardSuspendedError
            $rootScope.initialized = false
            LoadingStatus.error(LoadingStatus.Messages.SuspendedDashboardMessage($rootScope.organizationId))
            return

        return $q.reject(error)


doHierarchySmartGroupSwitchingAnimation = ($timeout) ->
    # Hierarchy switch Segments (smart groups) change animation. It would be nice if this
    # dom stuff was in a directive, but I don't know how to refactor this yet.
    oldSmartGroupsPanel = do ->
        $el = $('.smart-groups-panel')
        $el: $($el[0]?.outerHTML)
        offset: $el.offset()
        width:  $el.width()
        height: $el.height()
    return if not oldSmartGroupsPanel.$el
    # This is some trick to prevent the animation from showing when the dashboard first loads.
    isBeforeGroupsInitialization = oldSmartGroupsPanel.$el.find(".smart-groups li").length is 0
    return if isBeforeGroupsInitialization
    $('#sidebar main').append(oldSmartGroupsPanel.$el)
    oldSmartGroupsPanel.$el.addClass('.smart-groups-panel-old')
    oldSmartGroupsPanel.$el.css
        'z-index': 9
        'user-select': 'none'
        'border-left': '1px solid #e9eaed'
        position: 'fixed'
        top:    oldSmartGroupsPanel.offset.top
        left:   oldSmartGroupsPanel.offset.left-1
        height: oldSmartGroupsPanel.height
        width:  oldSmartGroupsPanel.width+2
        opacity: 1
    $timeout (-> oldSmartGroupsPanel.$el.css
        transform: 'translate3d(240px, 0px, 0px)'
        opacity: 0
    ), 0
    $timeout (->
        oldSmartGroupsPanel.$el.remove()
        delete oldSmartGroupsPanel.$el
    ), 1000


module.factory 'AccessControl', (StorageAPI) ->

    fetch = ->
        return StorageAPI('accessControl').then((api) -> api.get())

    normalizeStatus = (status) ->
        status = status or 'active'
        status = status.toString().toLowerCase()
        return status

    return fetch().then (accessControl) ->
        accessControl = accessControl or {}
        accessControl.status = normalizeStatus(accessControl.status)
        accessControl.filters ?= {}
        return accessControl


module.service "ItemConfig", ($q, CONFIG, DataDescriptors, Utils) ->
    config = Utils.copy(CONFIG.items or {})
    config.properties ?= {}
    config.properties.category ?= 'category'
    config.associations ?= []
    promise = (do ->
        return $q.when(config) if config.hierarchy
        DataDescriptors.fetch().then (descriptors) ->
            config.hierarchy = (descriptors.items.map (x) -> x.name).sort()
            return config
    )
    fetch: -> promise.then (x) -> Utils.copy(x)


module.service "StoreConfig", ($q, CONFIG, DataDescriptors, Utils) ->
    config = Utils.copy(CONFIG.stores or {})
    promise = (do ->
        return $q.when(config) if config.hierarchy
        DataDescriptors.fetch().then (descriptors) ->
            config.hierarchy = (descriptors.stores.map (x) -> x.name).sort()
            return config
    )
    fetch: -> promise.then (x) -> Utils.copy(x)


module.service 'QueryMetrics', ($rootScope, $q, CONFIG, QueryServiceAPI, Utils) ->
    TEMPLATED_KEYS = ['headerGroup', 'headerName']
    cache = null

    applyCurrencyToMetrics = (metrics, currency) ->
        currency = do ->
            currency ?= $rootScope.currencyModel?.selected
            return currency if not _.isString(currency)
            currency  = currency.toLowerCase()
            available = $rootScope.currencyModel?.available or []
            return _.find available, (x) -> x.id is currency
        symbol = currency?.symbol or "$"
        metrics.forEach (metric) -> TEMPLATED_KEYS.forEach (key) ->
            metric[key] = (metric[key] or "").replace('[currency]', symbol)
        return metrics


    applyCategoryOverridesToMetrics = (metrics, categoryOverrides) ->
        metrics = Utils.copy(metrics)
        metricsByCategory = _.groupBy metrics, (x) -> x.category

        overrides = do ->
            result = Utils.copy(categoryOverrides or {})
            return {} if not _.isObject(result)

            result = Object.keys(result).map (category) ->
                categoryOverride = result[category]
                categoryMetrics = metricsByCategory[category] or []
                if categoryMetrics.length is 0
                    console.warn("Can't override metric category, the category does not exist:", category)
                return categoryMetrics.map (x) ->
                    override = Utils.copy(categoryOverride)
                    override.field = x.field
                    return override

            result = _.flatten(result)
            result = _.keyBy(result, (x) -> x.field)

            return Object.keys(result).reduce((obj, x) ->
                delete result[x].field
                obj[x] = result[x]
                return obj
            , {})

        return applyOverridesToMetrics(metrics, overrides)


    applyOverridesToMetrics = (metrics, overrides) ->
        metrics = Utils.copy(metrics)
        metricsByField = _.keyBy metrics, (x) -> x.field

        overrides = do ->
            result = Utils.copy(overrides or {})
            return {} if not _.isObject(result)
            return result

        for field in Object.keys(overrides)
            override = overrides[field]
            if not _.isObject(override)
                console.warn "Metric override `#{field}` is not an object."
                console.warn override
                continue
            metric = metricsByField[field]
            delete override.field
            if not metric
                console.warn "Metric override `#{field}` has no matching metric."
                console.warn override
                continue

            templateOptions = {interpolate:/{{([\s\S]+?)}}/g}
            for key in Object.keys(override)
                override[key] = _.template(override[key], templateOptions)(metric)

            _.extend(metric, override)

        return metrics

    filterMetricsByWhitelist = (metrics) ->
        metricsByField = _.keyBy metrics, (x) -> x.field
        whitelist = do ->
            userEnabledKpis = $rootScope.accessControl?.kpis
            orgEnabledKpis = CONFIG.views?.metrics?.kpis
            return null if not orgEnabledKpis
            return _.compact(_.uniq(_.concat(orgEnabledKpis, userEnabledKpis)))
        return metrics if not whitelist
        return whitelist.map((x) -> metricsByField[x]).filter((x) -> x)

    fetchQueryServiceMetrics = do ->
        cache = null
        return ->
            cache ?= QueryServiceAPI().then((api) -> api.getMetrics())
            return cache.then (x) -> Utils.copy(x)


    fetchConfiguredMetricDefinitions = -> $q.when do ->
        definitions = Utils.copy(CONFIG.kpis?.definitions or {})
        return Object.keys(definitions).map (id) ->
            definition = definitions[id]
            definition.field = id
            return definition

    fetchCustomFoundationMetrics = -> $q.when do ->
        foundations = Utils.copy(CONFIG.kpis?.foundations or {})
        return Object.keys(foundations).reduce ((result, foundation) ->
            foundation = foundations[foundation]
            Object.keys(foundation).map (id) ->
                metric = foundation[id]
                metric.field = id
                result[id] = metric
            return result
        ), {}

    fetchCustomFilteredMetricsAsDefinitions = (metrics, definitions) -> $q.when do ->
        metricsByField = _.keyBy(metrics, 'field')
        definitionsByField = _.keyBy(definitions, 'field')
        metricFilters = Utils.copy(CONFIG.kpis?.filters or {})
        return Object.keys(metricFilters).reduce(((result, id) ->
            {label, metrics} = metricFilters[id]
            return result.concat _.compact metrics.map (metricId) ->
                field = "#{id}_#{metricId}"
                if definitionsByField[field]
                    console.warn('Filtered metric', field, 'already defined.')
                    return
                metric = metricsByField[metricId]
                if not metric
                    console.warn('Filtered metric', field, 'is missing definition for', metricId)
                    return
                definition = Utils.copy(metric)
                definition.headerGroup = "#{label} #{definition.headerGroup}"
                definition.field = field
                definition.query = field
                return definition
        ), [])

    fetchBaseMetrics = ->
        $q.all([
            fetchQueryServiceMetrics()
        ,   fetchCustomFoundationMetrics()
        ]).then ([queryServiceMetrics, customFoundationMetrics]) ->
            queryServiceMetrics     = _.keyBy(queryServiceMetrics, 'field')
            customFoundationMetrics = _.keyBy(customFoundationMetrics, 'field')
            return _.values(_.assign(queryServiceMetrics, customFoundationMetrics))

    fetchMetrics = ->
        $q.all([
            fetchBaseMetrics(),
            fetchConfiguredMetricDefinitions()
        ]).then ([metrics, definitions]) ->
            fetchCustomFilteredMetricsAsDefinitions(metrics, definitions).then (definitionFilters) ->
                definitions = definitions.concat(definitionFilters)
                metrics = metrics.concat(definitions)
                return metrics
        .then (metrics) ->
            return filterMetricsByWhitelist(metrics)
        .then (metrics) ->
            return applyCategoryOverridesToMetrics(metrics, CONFIG.kpis?.categoryOverrides)
        .then (metrics) ->
            return applyOverridesToMetrics(metrics, CONFIG.kpis?.overrides)

    getMetrics = ->
        cache ?= fetchMetrics()
        return cache.then((x) -> Utils.copy x)

    applyCurrencyToMetrics: applyCurrencyToMetrics

    fetch: (currency) ->
        getMetrics()
        .then (metrics) ->
            return applyCurrencyToMetrics(metrics, currency)



module.service 'HourProperty', ($q, CONFIG) -> fetch: -> $q.when do ->
    return null if not CONFIG.flags?.showHourDimension
    property = 'transactions.timestamp__hour'
    id:    property
    label: 'Hour'
    sort: {field:property, order:1}


module.service 'CalendarProperties', ['$q', ($q) -> fetch: ->
    $q.when(DatabaseDescriptorsService.fetch()).then (descriptors) ->
        return null if not descriptors.calendar
        hasQuarter = !!_.find descriptors.calendar, (x) -> x.name is 'quarter_label'
        hasSeason  = !!_.find descriptors.calendar, (x) -> x.name is 'season_label'
        return _.compact([
            {
                column: "timestamp"
                group:  "calendar"
                id:     "calendar.timestamp"
                label:  "Day"
                plural: "Days"
                table:  "calendar"
            }
            {
                column: "week"
                group:  "calendar"
                id:     "calendar.week"
                label:  "Week"
                plural: "Weeks"
                table:  "calendar"
            }
            {
                column: "month_label"
                group:  "calendar"
                id:     "calendar.month_label"
                label:  "Month"
                plural: "Months"
                table:  "calendar"
            }
            if hasQuarter then {
                group:  "calendar"
                id:     "calendar.quarter_label"
                label:  "Quarter"
                plural: "Quarters"
                table:  "calendar"
                column: "quarter_label"
            }
            if hasSeason then {
                column: "season"
                group:  "calendar"
                id:     "calendar.season_label"
                label:  "Season (Calendar)"
                plural: "Season (Calendar)"
                table:  "calendar"
            }
            {
                column: "year"
                group:  "calendar"
                id:     "calendar.year"
                label:  "Year"
                plural: "Years"
                table:  "calendar"
            }
            {
                column: "period_label"
                group:  "calendar"
                id:     "calendar_periods.period_label"
                label:  "Calendar Period"
                plural: "Calendar Periods"
                table:  "calendar_periods"
            }
        ])
]
