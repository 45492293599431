import _ from 'lodash'
import $ from 'jquery'
import 'jstree'
import { logError } from '../../lib/analytics'

#
###*
42 model -> jstree model
@argument {{
    element: HTMLElement,
    options: {showAll:boolean, hideCategorize:boolean},
    model: {
        selected: import('../../lib/types').IMetricDefinition[],
        available: import('../../lib/types').IMetricDefinition[]
    }
}} params
###
export createMetricSelect = (params) ->
    {
        document,
        element,
        model,
        onChange,
        options
    } = params

    $element = $(element)
    $document = $(document ? $element[0]?.ownerDocument)

    getSelected = ->
        # do a DFS to list the IDs of the children
        # this method returns the nodes in their order in the tree, not the order they're selected
        getBottomCheckedOrdered = (arr, childrenFilter) ->
            return arr.flatMap (x) ->
                return getBottomCheckedOrdered x.children, childrenFilter if x.children.length > 0
                return if childrenFilter(x) then x.id else []

        return getBottomCheckedOrdered(element.jstree('get_json'), (x) -> x.state.checked)


    # jstree general config
    createConfig = (metricsTreeData) ->
        core:
            data: metricsTreeData
            themes:
                icons: false
            check_callback: (operation, node, node_parent, node_position, more) ->
                return false if operation in ['create_node', 'editMode_node', 'delete_node', 'copy_node']
                return node.id in node_parent.children # only allow rearranging children of nodes, not changing structure.
        plugins: ['checkbox', 'dnd', 'search']
        checkbox:
            tie_selection: false
        dnd:
            copy: false
            drag_selection: false
        search:
            fuzzy: false
            show_only_matches: true
            show_only_matches_children: true


    createNewChild = (metric, selectedMetricFields) ->
        id: metric.field
        text: metric.headerName
        data: {metric}
        order: do ->
            indexOfChild = selectedMetricFields.indexOf(metric.field)
            return indexOfChild if indexOfChild > -1
            return selectedMetricFields.length + 1
        state:
            checked: selectedMetricFields.includes(metric.field)


    createData = (selected, available, options) ->
        hideCategorize = options?.hideCategorize
        header = {}
        headerList = []
        metricTree = available.reduce ((prev, curr) ->
            if curr.headerGroup isnt header.text
                if curr.headerGroup and headerList.indexOf(curr.headerGroup) > -1
                    metricItem = prev.find((item) -> item.id is curr.headerGroup)

                    if not metricItem
                        logError(new Error("Error while buildind Grid: #{curr.headerGroup} not found for metric #{curr.field}"))
                        return prev

                    metricItem.children.push(createNewChild(curr, selected))
                    metricItem.children = _.sortBy metricItem.children, (x) -> x.order
                    return prev

                header =
                    children: []
                    text:     curr.headerGroup
                    id:       curr.headerGroup
                    category: curr.category ? 'Uncategorized'

                headerList.push(curr.headerGroup)
                prev.push(header)
            if curr.headerName is ''
                header.id = curr.field
                header.state = checked:(curr.field in selected)
                header.data = metric: curr
                header.category = curr.category
                header.order = do ->
                    indexOfChild = selected.indexOf(curr.field)
                    return indexOfChild if indexOfChild > -1
                    return selected.length + 1
            else
                header.children.push(createNewChild(curr, selected))
                header.children = _.sortBy header.children, (x) -> x.order
            return prev
        ), []

        getFirstChild = (a) -> _.minBy(a.children, (child) -> child.order)
        metricGroups = _.sortBy metricTree, (x) ->
            return x.order if Number.isInteger(x.order)
            return getFirstChild(x)?.order

        return metricGroups if hideCategorize

        metricCategoryGroups = metricGroups.reduce(((acc, metricGroup, index) ->
            category = metricGroup.category
            acc[category] ?= {
                id: index
                text: category
                li_attr: { class: 'category' }
                children: []
            }

            acc[category].children.push(metricGroup)

            return acc
        ), {})

        categories = Object.keys(metricCategoryGroups)
        return metricCategoryGroups[categories[0]].children if categories.length is 1
        return Object.values(metricCategoryGroups)

    setSearch = (value) ->
        element.jstree('search', value)
        return

    hideAllNodes =  (_, str) ->
        if str.nodes.length is 0
            $element.jstree(true).hide_all(false)

    showJsTreeIfHidden = ->
        areAllNodesHidden = do ->
            return false if not $element.jstree(true)?.get_json
            nodes = $element.jstree(true).get_json('#', {flat:false}) or []
            return nodes.every((node) ->
                return $element.jstree(true).is_hidden(node)
            )

        if areAllNodesHidden
            $element.jstree(true).show_all(false)

    events =
        element: 'loaded.jstree check_node.jstree uncheck_node.jstree'
        document: 'dnd_stop.vakata'

    openJSTreeFirstLevel = ->
        nodes = $element.jstree(true).get_json('#', {flat:false}) or []
        nodes.forEach((node) -> $element.jstree().open_node({ "id": node.id }))

    attach = (config) ->
        $element.jstree(config)
        $element.on('search.jstree', hideAllNodes)
        $element.on(events.element, onChange)
        $document.on(events.document, onChange)

        # $element.on('ready.jstree', openJSTreeFirstLevel) if not options.hideCategorize

    destroy = ->
        $element.jstree('destroy')
        $element.off('search.jstree', hideAllNodes)
        $element.off(events.element, onChange)
        $document.off(events.document, onChange)

        # $element.off('ready.jstree', openJSTreeFirstLevel) if not options.hideCategorize

    data = do ->
        data = createData(model.selected, model.available, options)
        return data if not options?.addAllMetrics

        li_attr = {}
        text = 'All Metrics'

        if not options?.hideCategorize
            li_attr = { class: 'category' }
            text = 'All Categories'

        return {id: '__all_metrics', text, state: {opened: true}, children: data, li_attr }

    attach(createConfig(data))

    return {getSelected, setSearch, destroy, showJsTreeIfHidden}
