import Utils from '../../lib/utils';
import { IMetricsFunnelNode } from './metrics-grid.directive';

export interface IMetricsPageTabViewModelParams {
    id?: string;
    name?: string;
    funnel: IMetricsFunnelNode;
}

export class MetricsPageTabViewModel {
    id: string;
    funnel: IMetricsFunnelNode;
    name: string;
    constructor(params: IMetricsPageTabViewModelParams) {
        this.id = params.id ?? Utils.uuid();
        this.name = params.name ?? 'New View';
        this.funnel = params.funnel;
    }
}
