import _ from 'lodash';

// TODO
// FIXME: [DEV-1811] Hardcoded metrics

const ORGS_OVERRIDES: Record<string, (data: Record<string, unknown>) => Record<string, unknown>> = {
    sportsdirect: (data: Record<string, unknown>) => {
        if (_.has(data, 'store_budget')) {
            data['growth_store_budget_prev'] = data['net_sales'];
            data['growth_store_budget'] = data['diff_to_store_budget_percentage'];
        }

        return data;
    },
    hatchcollection: (data: Record<string, unknown>) => {
        if (_.has(data, 'gross_sales_rof')) {
            data['growth_gross_sales_rof_prev'] = data['gross_sales'];
            data['growth_gross_sales_rof'] = data['diff_to_gross_sales_rof'];
        }
        if (_.has(data, 'gross_sales_plan')) {
            data['growth_gross_sales_plan_prev'] = data['gross_sales'];
            data['growth_gross_sales_plan'] = data['diff_to_gross_sales_plan'];
        }
        return data;
    },
    mizzenandmain: (data: Record<string, unknown>) => {
        if (_.has(data, 'store_budget_traffic')) {
            data['growth_store_budget_traffic'] = data['diff_to_store_budget_traffic_percentage'];
            data['growth_store_budget_traffic_prev'] = data['traffic'];
        }
        if (_.has(data, 'budget_conversion')) {
            data['growth_budget_conversion'] = data['diff_to_budget_conversion_percentage'];
            data['growth_budget_conversion_prev'] = data['conversion'];
        }
        if (_.has(data, 'store_budget_demand_dollar_per_transaction')) {
            data['growth_store_budget_demand_dollar_per_transaction'] =
                data['diff_to_store_budget_demand_dollar_per_transaction_percentage'];
            data['growth_store_budget_demand_dollar_per_transaction_prev'] =
                data['demand_gross_dollar_per_transaction'];
        }
        if (_.has(data, 'budget_demand_transaction_count')) {
            data['growth_budget_demand_transaction_count'] = data['diff_to_budget_demand_transaction_count_percentage'];
            data['growth_budget_demand_transaction_count_prev'] = data['demand_transaction_count'];
        }
        if (_.has(data, 'budget_demand_gross')) {
            data['growth_budget_demand_gross'] = data['diff_to_budget_demand_gross_percentage'];
            data['growth_budget_demand_gross_prev'] = data['demand_gross_sales'];
        }
        if (_.has(data, 'budget_conversion')) {
            data['growth_budget_conversion'] = data['diff_to_budget_conversion_percentage'];
            data['growth_budget_conversion_prev'] = data['demand_conversion'];
        }

        return data;
    },
    sony_rfp_demo: (data: Record<string, unknown>) => {
        if (_.has(data, 'net_sales_units')) {
            data.growth_net_sales_units = data.diff_to_net_sales_units_budget_percentage;
            data.growth_net_sales_units_prev = data.net_sales_units_budget;
        }
        return data;
    },
    totes: (data: Record<string, unknown>) => {
        if (_.has(data, 'future_on_order_units')) {
            data.growth_future_on_order_units = null;
            data.growth_future_on_order_units_prev = null;
        }
        if (_.has(data, 'future_on_order_at_price')) {
            data.growth_future_on_order_at_price = null;
            data.growth_future_on_order_at_price_prev = null;
        }
        return data;
    },
} as const;

export const modifyBudgetData = (data: Record<string, unknown>, organization: string) => {
    if (_.has(data, 'budget_profit')) {
        data['growth_budget_profit'] = data['diff_to_budget_profit_percentage'];
        data['growth_budget_profit_prev'] = data['profit'];
    }

    if (_.has(data, 'latest_estimate')) {
        // prettier-ignore
        data['growth_latest_estimate_prev'] =
            'net_sales_without_taxes' in data ?
                data['net_sales_without_taxes'] :
                data['net_sales'];
        data['growth_latest_estimate'] =
            'diff_to_latest_estimate_percentage_without_taxes' in data
                ? data['diff_to_latest_estimate_percentage_without_taxes']
                : data['diff_to_latest_estimate_percentage'];
    }

    if (_.has(data, 'budget')) {
        // prettier-ignore
        data['growth_budget_prev'] =
            'net_sales_without_taxes' in data ?
                data['net_sales_without_taxes'] :
                data['net_sales'];
        data['growth_budget'] =
            'diff_to_budget_percentage_without_taxes' in data
                ? data['diff_to_budget_percentage_without_taxes']
                : data['diff_to_budget_percentage'];
    }

    const overrideId = Object.keys(ORGS_OVERRIDES).find(org => organization.includes(org));
    const override = overrideId ? ORGS_OVERRIDES[overrideId] : null;
    return override ? override(data) : data;
};
