import _ from 'lodash';
import { titleize } from 'inflected';
import { normalizeCalendarDateFactoryConfig } from '@42technologies/calendar';
import { ICalendar, ICalendarDatepicker, ICalendarDatepickerActionOverride } from '../lib/types';
import { QueryServiceAPI } from '../lib/api';
import { ConfigAPI } from '../lib/config-api';
import { isObject } from '../lib/utils/utils-object';
import { logError } from '../lib/analytics';

const DEFAULT_DATEPICKER_TYPE = 'nrf';

export const CalendarConfigService = {
    get(
        config: Partial<{ datepicker: unknown; calendars: unknown[]; calendar: unknown }>,
        availableCalendarIds: null | string[],
    ): ICalendar[] {
        const normalized = Array.from(normalize(config));
        const resolved = Array.from(resolve(normalized, availableCalendarIds));
        console.info('Configured Calendars:');
        for (const calendar of resolved) console.log(calendar);
        console.groupEnd();
        return resolved;
    },
    async fetch(): Promise<ICalendar[]> {
        const [config, calendarIds] = await Promise.all([
            ConfigAPI.get().then(api => api.organization.get()),
            AvailableCalendarIds.fetch(),
        ]);
        return this.get(config, calendarIds);
    },
};

const AvailableCalendarIds = {
    async fetch(): Promise<null | string[]> {
        const api = await QueryServiceAPI.get();
        const rows = await api.organizations.doQuery({ queryId: 'findCalendarTypes', query: {} });
        if (rows.length === 0) {
            logError(new Error('Query service findCalendarTypes returned no rows.'));
            return null;
        } else {
            const ids = rows.flatMap(x => (typeof x.id === 'string' ? x.id : []));
            if (ids.length >= 1) return ids;
            logError(new Error('Database missing important column: calendar.type'));
            return null;
        }
    },
};

function normalize<T extends Partial<{ datepicker: unknown; calendars: unknown[]; calendar: unknown }>>(
    config: T,
): Iterable<UnresolvedCalendar> {
    if ('calendars' in config) {
        return normalizeCalendars(config.calendars ?? []);
    }
    if ('calendar' in config) {
        const calendars = _.compact([config.calendar]);
        return normalizeCalendars(calendars);
    }
    if ('datepicker' in config) {
        return normalizeLegacyCalendarConfig(config.datepicker);
    }
    throw new Error('No calendar config found. Need one of: config.calendars, config.calendar, config.datepicker');
}

function* resolve(calendars: UnresolvedCalendar[], availableCalendarIds: null | string[]): Iterable<ICalendar> {
    const withId = resolveCalendarId(calendars, availableCalendarIds);
    for (const calendar of withId) {
        const label = resolveCalendarLabel(calendar);
        const datepicker = {
            ...calendar.datepicker,
            ...resolveDatepicker(calendar),
        };
        yield { ...calendar, label, datepicker };
    }
}

function* normalizeCalendars(calendars: unknown): Iterable<UnresolvedCalendar> {
    if (!Array.isArray(calendars)) {
        yield* normalizeCalendars(_.compact([calendars]));
    } else {
        for (const calendar of calendars) {
            if (!isObject(calendar)) throw new Error('Invalid config, calendar must be an object or array of objects');
            const normalized = normalizeCalendar(calendar);
            yield normalized;
        }
    }
}

function normalizeCalendar(calendar: unknown): UnresolvedCalendar {
    if (!isObject(calendar)) {
        throw new Error('Invalid calendar; must be an object');
    }
    if (!isObject(calendar.datepicker)) {
        throw new Error('Invalid calendar.datepicker; must be an object');
    }

    const actions = normalizeDatepickerActions(calendar.actions);
    const datepicker = normalizeDatepicker({
        ...calendar.datepicker,
        ...(actions ? { actions } : {}),
    });

    const id = (() => {
        if (typeof calendar.id === 'string') return calendar.id;
        return null;
    })();

    const label = (() => {
        if (typeof calendar.label === 'string') return calendar.label;
        return;
    })();

    return { id, label, datepicker, ...(actions ? { actions } : {}) };
}

function normalizeDatepicker(datepicker: unknown): ICalendarDatepicker {
    if (!isObject(datepicker)) throw new Error('Invalid datepicker config; must be an object');
    const type =
        typeof datepicker.type === 'string'
            ? datepicker.type
            : (() => {
                  console.error('Missing datepicker type. Please add type: nrf. Falling back to default.');
                  return DEFAULT_DATEPICKER_TYPE;
              })();
    const actions = normalizeDatepickerActions(datepicker.actions);
    return {
        ...normalizeCalendarDateFactoryConfig({ ...datepicker, type }),
        ...(actions ? { actions } : {}),
    };
}

function normalizeDatepickerAction(action: unknown): ICalendarDatepickerActionOverride {
    if (typeof action === 'string') return { id: action, hide: false };
    if (!isObject(action)) throw new Error('Invalid datepicker action override; must be an object or a string.');
    const id = action.id;
    if (typeof id !== 'string') throw new Error('Invalid datepicker action override; missing required: id');
    const result: ICalendarDatepickerActionOverride = { id };
    if (typeof action.hide === 'boolean') result.hide = action.hide;
    if (typeof action.label === 'string') result.label = action.label;
    if (typeof action.section === 'boolean') result.section = action.section;
    if (typeof action.group === 'string') result.group = action.group;
    return result;
}

function normalizeDatepickerActions(actions: unknown): undefined | ICalendarDatepickerActionOverride[] {
    if (!Array.isArray(actions)) return undefined;
    const result = actions.reduce<ICalendarDatepickerActionOverride[]>((result, action, index) => {
        try {
            result.push(normalizeDatepickerAction(action));
        } catch (error: any) {
            console.error(`Invalid datepicker action at index ${index}:`, error?.message);
        }
        return result;
    }, []);
    if (result.length === 0) return undefined;
    return result;
}

function normalizeLegacyCalendarConfig(config: unknown): Iterable<UnresolvedCalendar> {
    if (!isObject(config)) throw new Error('Config must be an object');
    const id = config.calendar ?? config.id;
    const label = config.label;
    const actions = config.actions;
    const datepicker = _.omit(config, 'calendar', 'actions');
    return normalizeCalendars([{ id, label, actions, datepicker }]);
}

type UnresolvedCalendar = Omit<ICalendar, 'label'> & {
    label?: undefined | string;
    actions?: ICalendarDatepickerActionOverride[];
};

// availableCalendarIds:
// - null: we don't have a calendar.type column
// - array: should be >= 1
function resolveCalendarId<C extends { id: null | string; datepicker: ICalendarDatepicker }>(
    calendars: C[],
    availableCalendarIds: null | string[],
): C[] {
    if (!Array.isArray(calendars) || calendars.length === 0) {
        throw new Error('Invalid calendars argument; must have length greater than 0.');
    }
    if (Array.isArray(availableCalendarIds) && availableCalendarIds.length === 0) {
        throw new Error('Invalid availableCalendarIds argument; must be null or have length greater than 0.');
    }

    // This happens when the database doesn't have a calendar.type
    if (availableCalendarIds === null) {
        const calendar = calendars[0];
        if (calendar && calendars.length === 1) return [{ ...calendar, id: null }];
        throw new Error("Multiple calendars configured, but database doesn't support it.");
    }

    // Legacy path: We don't defined calendar ids, and the backend has a calendar available
    // We'll just proceed without specifying a calendar id, which means we won't send it
    if (calendars.length === 1 && calendars[0]?.id === null && availableCalendarIds.length <= 1) {
        return calendars;
    }

    const withIdMatch = calendars.filter(x => x.id && availableCalendarIds.includes(x.id));
    const withoutIdMatch = calendars.filter(x => x.id && !availableCalendarIds.includes(x.id));
    if (withoutIdMatch.length > 0) console.error('Some calendars cannot be matched to the db:', withoutIdMatch);

    // Good path: the config references calendars that are available in the database
    if (withIdMatch.length > 0) {
        return withIdMatch;
    }
    // Bad path: This probably won't work, but we assume there's a config mismatch
    if (withoutIdMatch[0] && withoutIdMatch.length === 1 && availableCalendarIds.length === 1) {
        logError(
            `Invalid calendar id: ${withoutIdMatch[0]}; could not be matched to calendar.type ${availableCalendarIds[0]}.`,
        );
        return [{ ...withoutIdMatch[0], id: availableCalendarIds[0] }];
    }
    if (withoutIdMatch.length > 0) {
        throw new Error('No configured calendars are available in the database');
    }

    // at this point, we only have calendars with no ids, so we init using the datepicker type...
    calendars = calendars.map(x => ({ ...x, id: x.datepicker.type }));

    // Make sure we don't have duplicates
    const seen = new Set<string>();
    for (const calendar of calendars) {
        if (calendar.id === null) throw new Error('Calendar has no id and no datepicker type');
        if (seen.has(calendar.id)) throw new Error('Duplicate calendars configured; Set a calendar.id.');
        seen.add(calendar.id);
    }

    // we try again, this time using the datepicker types...
    return resolveCalendarId(calendars, availableCalendarIds);
}

function resolveCalendarLabel<C extends UnresolvedCalendar>(calendar: C): string {
    if (calendar.label) {
        return calendar.label;
    }
    const datepicker = calendar.datepicker;
    if (calendar.id && calendar.id !== datepicker.type) {
        return titleize(calendar.id);
    }
    if (datepicker.type === 'nrf') {
        const type = 'NRF';
        const pattern = datepicker.pattern ?? null;
        const month = titleize(datepicker.startMonth);
        return _.compact([type, pattern, month]).join(' ');
    }
    const type = titleize(datepicker.type);
    const month = titleize(datepicker.startMonth);
    return `${type} ${month}`;
}

function resolveDatepicker<T extends UnresolvedCalendar>(calendar: T) {
    const datepicker = resolveDatepickerActions(calendar);
    return datepicker;
}

function resolveDatepickerActions<T extends UnresolvedCalendar>(calendar: T) {
    const actions = calendar.datepicker.actions ?? calendar.actions;
    if (!actions) return calendar.datepicker;
    return { ...calendar.datepicker, actions };
}
