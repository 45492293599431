import _ from 'lodash';
import { IMetricSelectorTreeInstance, createMetricSelect } from './metrics-selector-tree.builder';
import { IMetricDefinition } from '../../lib/types';
import { ToggleModel } from '../../lib/model/model-toggle';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
// import { ShadowDomDirectiveWrapper } from '../../lib/angular/directive-shadow-dom';
// import MetricsSelectorTreeCSS from './metrics-selection-tree.module.scss';

export class MetricsSelectorPanelFilterViewModel {
    value: string;
    constructor(value = '') {
        this.value = value;
    }

    clear() {
        this.value = '';
    }
}

export class MetricsSelectorPanelViewModel extends ToggleModel {
    filter: MetricsSelectorPanelFilterViewModel;
    constructor() {
        super();
        this.filter = new MetricsSelectorPanelFilterViewModel('');
    }
}

export class MetricsSelectorModel {
    readonly selected: string[];
    readonly available: IMetricDefinition[];
    readonly options: { addAllMetrics: boolean };
    readonly onChange: (metrics: IMetricDefinition[]) => void;
    readonly panel: MetricsSelectorPanelViewModel;

    constructor(props: {
        panel?: MetricsSelectorPanelViewModel;
        selected: (string | IMetricDefinition)[];
        available: IMetricDefinition[];
        options: { addAllMetrics?: boolean };
        onChange: (metrics: IMetricDefinition[]) => void;
    }) {
        this.panel = props.panel ?? new MetricsSelectorPanelViewModel();
        this.selected = props.selected.map(x => (typeof x === 'string' ? x : x.field));
        this.available = props.available;
        this.options = { addAllMetrics: true, ...props.options };
        this.onChange = props.onChange;
    }
}

interface MetricsSelectorTreeDirectiveScope extends angular.IScope {
    model: MetricsSelectorModel;
}
export const MetricsSelectorTreeDirective = () => [
    // '$compile',
    function MetricsSelectorTreeDirective(): angular.IDirective<MetricsSelectorTreeDirectiveScope> {
        // $compile: angular.ICompileService,
        // return ShadowDomDirectiveWrapper($compile, {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            // style: MetricsSelectorTreeCSS,
            template: `
                <article class="ui-metric-selector" ng-show="model.panel.isActive">
                    <header class="metric-filter-header">
                        <article class="metric-selector-filter">
                            <i class="icon-search"></i>
                            <input ng-model="model.panel.filter.value" placeholder="Filter Metrics"></input>
                            <button ng-click="model.panel.filter.clear()" ng-class="{active:model.panel.filter.value}">clear</button>
                        </article>
                    </header>
                    <div class="metric-selector-tree"></div>
                </article>
            `,
            link: function MetricSelectorTreeLink(scope, element) {
                const $metricSelectElement = $(element).find('.metric-selector-tree');
                let metricSelect: null | IMetricSelectorTreeInstance = null;

                const reload = () => {
                    metricSelect?.destroy();
                    metricSelect = createMetricSelect({
                        element: $metricSelectElement,
                        model: _.cloneDeep({ selected: scope.model.selected, available: scope.model.available }),
                        options: scope.model.options,
                        onChange: () => {
                            const metrics = metricSelect?.getSelected() ?? [];
                            if (_.isEqual(metrics, scope.model.selected)) return;
                            let selectedMetrics = scope.model.available.filter(x => metrics.includes(x.field));
                            selectedMetrics = _.sortBy(selectedMetrics, x => metrics.indexOf(x.field));
                            scope.model.onChange(selectedMetrics);
                        },
                    });
                };

                scope.$watch('model.panel.filter.value', (value: string) => {
                    metricSelect?.showJsTreeIfHidden();
                    metricSelect?.setSearch(value);
                });
                scope.$watch('model.panel.isActive', (isActive: boolean) => {
                    if (isActive) {
                        reload();
                    } else {
                        scope.model.panel.filter.clear();
                    }
                });
                scope.$on('$destroy', () => metricSelect?.destroy());
            },
        };
    },
];

export interface IMetricsSelectorModel {
    selected: (string | IMetricDefinition)[];
    available: IMetricDefinition[];
    options?: { addAllMetrics: false };
    onChange: (metrics: IMetricDefinition[]) => void;
}

interface MetricsSelectorDirectiveScope extends angular.IScope {
    onMetricsExpandClick: ($event: Event) => void;
    metricSelectorTreeModel: MetricsSelectorModel;
    panel: MetricsSelectorPanelViewModel;
    model: IMetricsSelectorModel;
}

export const MetricsSelectorDirective = () => [
    'OutsideElementClick',
    function MetricsSelectorDirective(
        OutsideElementClick: IOutsideElementClick,
    ): angular.IDirective<MetricsSelectorDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="ui-metric-selector-tree-container">
                    <button class="metric-expand" ng-click="onMetricsExpandClick($event)" ng-class="{active:panel.isActive}">
                        <i class="icon-pencil"></i>
                        <span>Edit Metrics</span>
                    </button>
                    <metric-selector-tree ng-if="metricSelectorTreeModel" model="metricSelectorTreeModel"></metric-selector-tree>
                </article>
            `,
            link: function MetricSelectorLink(scope, element) {
                const $element = $(element);
                scope.panel = new MetricsSelectorPanelViewModel();

                scope.onMetricsExpandClick = $event => {
                    $event.preventDefault();
                    $event.stopImmediatePropagation();

                    scope.panel.toggle();
                };

                scope.$watch(
                    'model',
                    model => {
                        if (!model) return;

                        scope.metricSelectorTreeModel = new MetricsSelectorModel({
                            selected: scope.model.selected,
                            available: scope.model.available,
                            options: {
                                ...{ addAllMetrics: false },
                                ...(scope.model.options ? scope.model.options : {}),
                            },
                            panel: scope.panel,
                            onChange: metrics => scope.model.onChange(metrics),
                        });
                    },
                    true,
                );

                const getRelativeParentElement = (element: HTMLElement | ParentNode): ParentNode | null => {
                    if (!element.parentNode) return null;
                    if (getComputedStyle(element.parentNode).position === 'relative') return element.parentNode;
                    return getRelativeParentElement(element.parentNode);
                };

                const updateMetricsTreeDropdownPosition = () => {
                    const uiMetricSelectorElement = $element.find('.ui-metric-selector')[0];
                    const metricExpandButtonElement = $element.find('.metric-expand')[0];
                    if (!uiMetricSelectorElement || !metricExpandButtonElement) return;

                    const relativeParentElement = getRelativeParentElement(uiMetricSelectorElement);
                    if (!relativeParentElement) return;
                    const relativeParentElementTop = relativeParentElement.getBoundingClientRect().top ?? 0;
                    const metricSelectorTreeTop =
                        metricExpandButtonElement.getBoundingClientRect().bottom - relativeParentElementTop;
                    // Update the top position of the metrics tree dropdown
                    uiMetricSelectorElement.style.top = `${metricSelectorTreeTop}px`;
                };

                const resizeObserver = new ResizeObserver(updateMetricsTreeDropdownPosition);
                resizeObserver.observe(element[0]);

                let elementClick = () => {};
                scope.$watch('panel.isActive', (isActive: boolean) => {
                    elementClick();
                    if (isActive) {
                        updateMetricsTreeDropdownPosition();
                        elementClick = OutsideElementClick(
                            scope,
                            $element.find('.ui-metric-selector, .metric-expand'),
                            () => {
                                scope.panel.close();
                            },
                        );
                    }
                });

                scope.$on('$destroy', () => {
                    resizeObserver.disconnect();
                });
            },
        };
    },
];

const MetricsSelectorTreeModule = angular
    .module('42.components.metrics-selector-tree', [])
    .directive('metricSelectorTree', MetricsSelectorTreeDirective())
    .directive('uiMetricSelectorTree', MetricsSelectorDirective());

export default MetricsSelectorTreeModule;
