export const DOMParser = globalThis.DOMParser;

export function isHTMLElement(node: null | Node | HTMLElement): node is HTMLElement {
    return !!node && 'querySelector' in node;
}

export const purifyText = (value: unknown) => {
    if (value === undefined || value === null) return '';
    const parser = new DOMParser();
    const unsafeHtml = `<div>${String(value)}</div>`;
    const elem = parser.parseFromString(unsafeHtml, 'text/html');
    return elem.body.innerText;
};

export const purifyURL = (value: unknown) => {
    if (typeof value !== 'string') return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(`<a href="${value}"></a>`, 'text/html');
    const childNode = doc.body.firstChild;
    return childNode instanceof HTMLAnchorElement ? childNode.href : '';
};
