import { buildSupportLink } from './support-email.helper';
import * as Analytics from '../../lib/analytics';
import { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { IConfigObj, IOrganization } from '../../lib/types';

interface MainNavBarDirectiveScope extends angular.IScope {
    handbookLink: string | undefined;
    supportLink: string | undefined;
    organization: IOrganization | undefined;
    navbarModel: {
        expand: boolean;
    };
    toggleNav: () => void;
    navigateToHandbook: () => void;
    disableNav: () => void;
    wideWindow: () => boolean;
}

const MainNavBarDirective = () => [
    '$window',
    'CONFIG',
    'OutsideElementClick',
    function MainNavBarDirectiveFn(
        $window: angular.IWindowService,
        CONFIG: IConfigObj,
        OutsideElementClick: IOutsideElementClick,
    ): angular.IDirective<MainNavBarDirectiveScope> {
        return {
            restrict: 'E',
            scope: true,
            replace: true,
            template: `
                <nav class="top-bar collapses" ng-class="{small: !wideWindow()}">
                    <div class="collapse-header">
                        <a class="header-logo" ng-href="{{ routes.overview.url }}">
                        <div class="logo">
                        </div>
                        </a>
                        <i ng-click="toggleNav()" ng-hide="wideWindow()" ng-class="{active: navbarModel.expand}" class="icon-menu"></i>
                    </div>
                    <ul ng-if="initialized" ng-click="disableNav()" class="ng-cloak report-select">
                        <div ng-if="$parent.activeRoute.group != 'reporting'" ng-class="{small: !wideWindow(), hide: !navbarModel.expand && !wideWindow()}" class="page-select collapses">
                            <route model="routes.overview"></route>
                            <route model="routes.metrics" ng-if="pages.metrics"></route>
                            <route model="routes.structure" ng-if="pages.structure"></route>
                            <route model="routes.customers" ng-if="pages.customers"></route>
                            <route model="routes.transactions" ng-if="pages.transactions"></route>
                            <route model="routes.grid"></route>
                            <route model="routes.inventory" ng-if="pages.inventory"></route>
                            <route model="routes.map" ng-if="pages.map"></route>
                            <route model="routes.chart" ng-if="pages.chart"></route>
                            <route model="routes.ads" ng-if="pages.ads"></route>
                            <route model="routes.reportingReports" ng-if="pages.reporting"></route>
                        </div>
                        <div ng-class="{small: !wideWindow(), hide: !navbarModel.expand && !wideWindow()}", ng-if="$parent.activeRoute.group == 'reporting'" class="page-select collapses">
                            <li><a ng-href="{{ routes.overview.url }}" target="{{ routes.overview.href && '_blank'}}"><i class="icon-left"></i><span class="route-label">Dashboard</span></a></li>
                            <route model="routes.reportingReports" submenu='true'></route>
                            <route model="routes.reportingSchedules"></route>
                        </div>
                    </ul>
                    <div ng-class="{small: !wideWindow(), hide: !navbarModel.expand && !wideWindow()}", ng-click="disableNav()" class="right collapses" ng-cloak="ng-cloak">
                        <div class="support">
                            <a ng-if="handbookLink" ng-href="{{ handbookLink }}" ng-click="navigateToHandbook()" target="_blank"><i class="icon-book"></i>Handbook</a>
                        </div>
                        <div class="support">
                            <a ng-if="!organization" ng-href="mailto:support@42technologies.com?subject=Support request"><i class="support-icon icon-paper-plane"></i>Support</a>
                            <a ng-if="organization" ng-href="{{ supportLink }}" target="_blank"><i class="support-icon icon-paper-plane"></i>Support</a>
                        </div>
                        <status-message-bar-button></status-message-bar-button>
                    </div>
                </nav>
            `,
            link: function MainBarDirectiveLink(scope, element) {
                scope.$watch('organization', () => {
                    scope.handbookLink = scope.organization?.handbook;
                    scope.supportLink = buildSupportLink(CONFIG, scope.organization);
                });

                OutsideElementClick(scope, element, () => {
                    if (!scope.navbarModel.expand) return;
                    scope.navbarModel.expand = false;
                });

                scope.toggleNav = () => (scope.navbarModel.expand = !scope.navbarModel.expand);
                scope.navigateToHandbook = () => Analytics.track(Analytics.EVENTS.USER_NAVIGATED_HANDBOOK);
                scope.disableNav = () => (scope.navbarModel.expand = false);
                scope.wideWindow = () => $window.innerWidth > 768;
            },
        };
    },
];

const module = angular.module('42.main.navbar', []);
module.directive('mainNavbar', MainNavBarDirective());
export default module;
