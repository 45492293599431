import _ from 'lodash';
import * as Analytics from '../../lib/analytics';
import * as ConfigFlags from '../../lib/config-flags';
import { StorageAPI } from '../../lib/storage-user-config';
import Utils from '../../lib/utils';
import { AngularInjected, deepStripAngularProperties } from '../../lib/angular';
import { IConfigObj, IMetricDefinition, IQuery } from '../../lib/types';
import { IPropertyDefinition } from '../../lib/config-hierarchy';
import { HierarchyService } from '../../modules/hierarchy/hierarchy.module';
import { DashboardRootScope, IQueryMetrics } from '../main-controller';
import { SmartGroupViewModel } from '../../modules/smart-groups/smart-groups.service';
import { TimeGroupings } from './store-chart-time-groupings';
import { IHighchartsModel } from './store-controller';
import { IDataDescriptorsService } from '../../modules/services';

export const ChartPageRouteConfigFactory = () =>
    function ChartPageRouteConfig(
        $routeProvider: angular.route.IRouteProvider,
        ROUTES: Record<string, unknown>,
        CONFIG: IConfigObj,
    ) {
        const override = _.pick(CONFIG.routes?.chart || CONFIG.routes?.stores || {}, 'label', 'url');
        const route: Record<string, unknown> = ROUTES.chart ? { ...ROUTES.chart, ...override } : { ...override };

        if (typeof route.url === 'string') {
            if (route.oldUrl && typeof route.oldUrl === 'string') {
                $routeProvider.when(route.oldUrl, { redirectTo: route.url });
            }

            $routeProvider.when(route.url, route);
        }
    };

// FIXME: centralize with definition from main-controller
const fetchHourProperty = (): Promise<IPropertyDefinition | null> => {
    return ConfigFlags.fetch().then(flags => {
        if (!flags.showHourDimension) return null;
        const property = 'transactions.timestamp__hour';
        return {
            id: property,
            label: 'Hour',
            sort: { field: property, order: 1 },
        };
    });
};

export interface IChartPageViewParams {
    groupBy: IPropertyDefinition[];
    metrics: IMetricDefinition[];
}

export interface IChartPageViewFactoryConfig {
    hierarchyId: string | undefined;
    segmentName: string | undefined;
}
export interface IChartPageViewConfig {
    hierarchyId: string | undefined;
    segmentName?: string | undefined;
    metrics: IMetricDefinition[];
    groupBy: IPropertyDefinition[];
    name?: string | undefined;
    id?: string;
    model?: IHighchartsModel | null | undefined;
}

export type IChartPageViewFactory = typeof ChartPageView;
export type IChartPageView = InstanceType<IChartPageViewFactory>;
class ChartPageView {
    id: string;
    name: string;
    model: IHighchartsModel | null = null;
    segmentName = '';
    params: IChartPageViewParams | undefined = undefined;

    constructor(options: IChartPageViewConfig) {
        this.id = options.id ?? Utils.uuid();
        this.model = options.model ?? null;
        this.name = options.name ?? 'New View';
        this.segmentName = options.segmentName || '';
        this.params = {
            groupBy: options.groupBy,
            metrics: options.metrics,
        };
    }

    setSegmentName(name: string) {
        this.segmentName = name || '';
    }
}

async function fetchGroupByHierarchy(hierarchyId?: string) {
    return Promise.all([HierarchyService().fetch(hierarchyId), fetchHourProperty()]).then(
        ([hierarchy, hourProperty]) => {
            if (hourProperty) hierarchy.groupBy.push(hourProperty);
            return hierarchy.groupBy;
        },
    );
}

interface StoreActionsPanelDirectiveScope extends angular.IScope {
    showTimeGrouping: boolean;
    save: () => void;
    togglePanel: () => void;
    hide: boolean;
}

export const StoreActionsPanelDirective = () => [
    'DataDescriptors',
    function StoreActionsPanelDirectiveFn(
        DataDescriptors: IDataDescriptorsService,
    ): angular.IDirective<StoreActionsPanelDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                tab: '=',
                onTogglePanelClick: '=',
                hide: '=',
            },
            replace: true,
            template: `
                <article class="store-actions-panel">
                    <div class="actions-panel-row">
                        <div class="selected-filters">
                            <store-funnel-state model="tab.model" label="tab.segmentName"> </store-funnel-state>
                        </div>
                        <div class="dropdown-actions">
                            <store-actions-panel-limit ng-if="tab.model.available" model="tab.model"> </store-actions-panel-limit>
                            <store-actions-panel-order model="tab.model"></store-actions-panel-order>
                            <store-actions-panel-metric model="tab.model"></store-actions-panel-metric>
                            <store-time-grouping model="tab.model" ng-if="showTimeGrouping"></store-time-grouping>
                        </div>
                    </div>
                    <div class="actions-panel-row last-row">
                        <store-funnel-properties model="tab.model"> </store-funnel-properties>
                        <div class="actions-hide-button">
                            <button class="button-toggle-actions-panel button-toggle-actions-panel-hide" ng-click="onTogglePanelClick()">
                                <span>Hide Panel</span>
                                <i class="icon-up-open"></i>
                            </button>
                        </div>
                    </div>
                </article>
            `,
            link: function StoreActionsPanelDirectiveLink($scope, $element) {
                let collapsed = false;
                void DataDescriptors.fetch().then(descriptors => ($scope.showTimeGrouping = !!descriptors.calendar));

                const getMainElement = () => {
                    const selector = '#view > .view.view-stores > main';
                    const element = document.querySelector<HTMLElement>(selector);
                    if (element) return element;
                    throw new Error(`element not found: ${selector}`);
                };

                const getMainBodyElement = (): HTMLElement => {
                    const selector = '#view > .view.view-stores > main > .store-main-body';
                    const element = document.querySelector<HTMLElement>(selector);
                    if (element) return element;
                    throw new Error(`element not found: ${selector}`);
                };

                const resizeObserverCallback = () => {
                    const height = $element[0].clientHeight;
                    const mainBodyElement = getMainBodyElement();
                    const mainElement = getMainElement();
                    mainBodyElement.style.position = 'absolute';
                    mainBodyElement.style.top = `${height + 39}px`;
                    mainBodyElement.style.height = `${mainElement.offsetHeight - height - 39}px`;
                };

                let setTimeoutInstance: number | undefined;
                const togglePanel = () => {
                    setTimeoutInstance && clearTimeout(setTimeoutInstance);

                    const mainBodyElement = getMainBodyElement();
                    mainBodyElement.style.transition = 'top 0.7s ease-in-out';
                    const mainElement = getMainElement();
                    if (collapsed) {
                        mainBodyElement.style.top = '39px';
                        mainBodyElement.style.height = `${mainElement.offsetHeight - 39}px`;
                    } else {
                        mainBodyElement.style.top = `${$element[0].offsetHeight + 39}px`;
                        mainBodyElement.style.height = `${mainElement.offsetHeight - $element[0].offsetHeight - 39}px`;
                    }

                    setTimeoutInstance = setTimeout(() => {
                        mainBodyElement.style.transition = 'none';
                    }, 1000);
                };

                resizeObserverCallback();
                const resizeActionsPanelObserver = new ResizeObserver(resizeObserverCallback);
                resizeActionsPanelObserver.observe($element[0]);
                resizeActionsPanelObserver.observe(getMainElement());

                $scope.$watch('hide', (hide: boolean) => {
                    if (typeof hide !== 'boolean') return;
                    if (hide !== collapsed) {
                        collapsed = !!hide;
                        togglePanel();
                    }
                });
                $scope.$on('$destroy', () => {
                    resizeActionsPanelObserver?.disconnect();
                });
            },
        };
    },
];

interface IChartPageTabConfig {
    id: string;
    filters?: Record<string, Record<string, string>>;
    name: string;
    limitBy?: number;
    grouping?: string;
    metric?: string;
    sortOrder?: number;
    timeGrouping?: string;
    stacking?: boolean;
    funnel?: {
        nodes: { id: string; choice?: string | undefined }[];
        selected: Record<string, unknown>;
    };
}

const resolveConfig = (
    tabConfig: IChartPageTabConfig,
    properties: IPropertyDefinition[],
    metrics: IMetricDefinition[],
    hierarchyId?: string,
): ChartPageView | null => {
    if (!tabConfig) {
        return null;
    }
    const {
        id: tabId,
        name,
        filters,
        sortOrder,
        grouping: propertyId,
        timeGrouping,
        metric: metricId,
        limitBy,
        stacking,
    } = tabConfig;
    const modelTimeGrouping = _.cloneDeep(TimeGroupings.find(t => t.id === timeGrouping) || TimeGroupings[0]);
    const id = tabId || Utils.uuid();

    const model: IHighchartsModel = {
        funnel: {
            nodes: [],
            selected: {},
        },
        selected: {
            filters: filters ?? {},
            timeGrouping: modelTimeGrouping,
            stacking: !!stacking,
            grouping: properties[0],
        },
    };

    if (sortOrder) {
        model.selected.sortOrder = { id: sortOrder };
    }

    if (propertyId) {
        const property = properties.find(p => p.id === propertyId);
        if (property) {
            model.selected.grouping = property;
        }
    }

    if (_.isNumber(limitBy)) {
        model.selected.limitBy = limitBy;
    }

    const metric = metrics.find(m => m.field === metricId);

    if (metric) {
        model.selected.metric = metric;
        model.selected.metric.id = metric.field;
    }

    if (tabConfig.funnel && Array.isArray(tabConfig.funnel.nodes) && tabConfig.funnel.nodes?.length > 0) {
        model.funnel ??= {};
        model.funnel.nodes = tabConfig.funnel.nodes.flatMap(node => {
            const property = properties.find(p => p.id === node.id);
            if (!property) return [];
            return [{ ...property, ...(node.choice ? { choice: node.choice } : {}) }];
        });
    }

    return new ChartPageView({
        hierarchyId,
        id,
        name,
        metrics,
        groupBy: properties,
        model,
    });
};

const normalizeTabToSave = (tab: ChartPageView): IChartPageTabConfig | null => {
    const { id, name, model: modelToSave } = tab;

    if (!modelToSave || !modelToSave.selected || !modelToSave.selected.metric) {
        return null;
    }

    const selectedTabView = deepStripAngularProperties(modelToSave.selected);
    const funnelNodes = modelToSave.funnel?.nodes?.map(node => deepStripAngularProperties(node)) || [];
    const funnel = {
        nodes: funnelNodes.map(node => ({ id: node.id, choice: node.choice })),
        selected: modelToSave.funnel?.selected ? deepStripAngularProperties(modelToSave.funnel?.selected) : {},
    };

    return {
        id,
        name,
        filters: selectedTabView.filters,
        limitBy: selectedTabView.limitBy,
        grouping: selectedTabView.grouping?.id,
        metric: selectedTabView.metric.field ?? selectedTabView.metric.id,
        sortOrder: selectedTabView.sortOrder.id,
        timeGrouping: selectedTabView.timeGrouping.id,
        stacking: selectedTabView.stacking,
        funnel,
    };
};

export interface IChartStorage {
    selected: string;
    available: IChartPageTabConfig[];
}

export const ChartViewTabServiceFactory = () => [
    function ChartViewTabServiceFn() {
        class ChartViewTabService {
            protected tabsConfig: IChartStorage | undefined | null;
            protected selectedTabId: string | undefined;
            protected tabs: ChartPageView[] | undefined;
            protected hierarchyId: string | undefined;
            protected HierarchyServiceInstance = HierarchyService();
            constructor() {}

            protected getStorageKey(hierarchyId?: string) {
                const prefix = 'chart.views.v1';
                return hierarchyId ? `${prefix}.${hierarchyId}` : prefix;
            }

            protected async saveTabsToStorage(tabsConfig: IChartStorage, hierarchyId?: string) {
                const storageKey = this.getStorageKey(hierarchyId);
                const api = await StorageAPI<IChartStorage>(storageKey);
                return api.put(tabsConfig);
            }

            protected async getTabsFromStorage(hierarchyId?: string) {
                const storageKey = this.getStorageKey(hierarchyId);
                const api = await StorageAPI<IChartStorage>(storageKey);
                return api.get();
            }

            protected async fetchTabsConfig(hierarchyId?: string): Promise<IChartStorage | null> {
                const state = await this.getTabsFromStorage(hierarchyId);
                if (!Utils.isObject(state)) return null;
                if (!Array.isArray(state?.available)) return null;

                const available = state.available.filter(Utils.isObject).map(tab => {
                    const id = typeof tab.id === 'string' ? tab.id : Utils.uuid();
                    return { ...tab, id };
                });
                if (state.available.length === 0) return null;

                const selected = (() => {
                    const id = state.selected;
                    if (!id) return available[0].id;
                    const tab = available.find(tab => tab.id === id) ?? available[0];
                    return tab.id;
                })();

                return { selected, available };
            }

            protected fetchTabsView(
                metrics: IMetricDefinition[],
                groupByHierarchy: IPropertyDefinition[],
                tabsConfig: IChartPageTabConfig[],
                hierarchyId?: string,
            ): ChartPageView[] {
                return _.compact(tabsConfig.map(t => resolveConfig(t, groupByHierarchy, metrics, hierarchyId)));
            }

            async saveTabs(selectedTabId: string, newTabs: ChartPageView[]) {
                const newTabsConfig: IChartStorage = {
                    selected: selectedTabId,
                    available: _.compact(newTabs.map(tab => normalizeTabToSave(_.cloneDeep(tab)))),
                };

                if (!_.isEqual(newTabsConfig, this.tabsConfig)) {
                    if (this.tabs) this.tabs = newTabs;
                    this.tabsConfig = newTabsConfig;
                    this.saveTabsToStorage(this.tabsConfig, this.hierarchyId);
                }
            }

            protected async getTabsConfig(hierarchyId?: string) {
                this.tabsConfig ??= await this.fetchTabsConfig(hierarchyId);
                return this.tabsConfig;
            }

            async getTabs(
                metrics: IMetricDefinition[],
                groupByHierarchy: IPropertyDefinition[],
                hierarchyId?: string,
            ): Promise<{ selectedTabId: string | undefined; tabs: ChartPageView[] }> {
                if (!this.tabs || this.hierarchyId !== hierarchyId) {
                    this.hierarchyId = hierarchyId;
                    const tabsConfig = await this.getTabsConfig(hierarchyId);

                    if (tabsConfig && tabsConfig.available.length > 0) {
                        this.selectedTabId = tabsConfig.selected;
                        this.tabs = this.fetchTabsView(metrics, groupByHierarchy, tabsConfig.available, hierarchyId);

                        await this.saveTabs(this.selectedTabId, this.tabs);
                    }
                }

                return _.cloneDeep({ selectedTabId: this.selectedTabId, tabs: this.tabs || [] });
            }

            async getData(metrics: IMetricDefinition[], groupByHierarchy: IPropertyDefinition[], hierarchyId?: string) {
                return this.getTabs(metrics, groupByHierarchy, hierarchyId).then(({ tabs, selectedTabId }) => {
                    return {
                        groupBy: groupByHierarchy,
                        metrics,
                        tabs,
                        selectedTabId,
                    };
                });
            }
        }

        return new ChartViewTabService();
    },
];

export type IChartViewTabService = AngularInjected<ReturnType<typeof ChartViewTabServiceFactory>>;

export const ChartPageTabsFactory = () => [
    '$q',
    'ChartViewTabService',
    'QueryMetrics',
    function ChartViewTabFn(
        $q: angular.IQService,
        ChartViewTabService: IChartViewTabService,
        QueryMetrics: IQueryMetrics,
    ) {
        class ChartPageTabs {
            hierarchyId: string | undefined;
            segmentName: string;
            tabs: ChartPageView[] = [];
            selectedTab: ChartPageView | undefined;
            metrics: IMetricDefinition[] = [];
            groupBy: IPropertyDefinition[] = [];

            constructor(options: IChartPageViewFactoryConfig) {
                this.hierarchyId = options.hierarchyId;
                this.segmentName = options.segmentName ?? '';
                void this.init();
            }

            private init() {
                return $q
                    .all([QueryMetrics.fetch(), fetchGroupByHierarchy(this.hierarchyId)])
                    .then(([metrics, groupByHierarchy]) => {
                        this.metrics = metrics;
                        this.groupBy = groupByHierarchy;

                        return $q
                            .when(ChartViewTabService.getData(this.metrics, this.groupBy, this.hierarchyId))
                            .then(({ metrics, tabs, groupBy, selectedTabId }) => {
                                const viewTabs = (tabs || []).map(storageTab => {
                                    const { id, name, model } = storageTab || {};
                                    const tab = new ChartPageView({
                                        hierarchyId: this.hierarchyId,
                                        segmentName: this.segmentName,
                                        id,
                                        name,
                                        metrics,
                                        groupBy,
                                        model,
                                    });

                                    return tab;
                                });

                                if (viewTabs.length > 0) {
                                    this.tabs = viewTabs;
                                    this.selectedTab = viewTabs.find(tab => tab.id === selectedTabId);
                                    return;
                                }

                                return this.addNewTab();
                            });
                    });
            }

            protected createNewTab() {
                const tab = new ChartPageView({
                    hierarchyId: this.hierarchyId,
                    segmentName: this.segmentName,
                    metrics: this.metrics,
                    groupBy: this.groupBy,
                });
                this.selectedTab = tab;
                this.tabs.push(tab);

                return this.save();
            }

            selectTab(tabToSelectId: string) {
                if (tabToSelectId !== this.selectedTab?.id) {
                    const selectedTabIndex = this.tabs.findIndex(tab => tab.id === tabToSelectId);
                    if (selectedTabIndex > -1) {
                        const oldTab = this.tabs[selectedTabIndex];
                        const tab = new ChartPageView({
                            hierarchyId: this.hierarchyId,
                            segmentName: this.segmentName,
                            metrics: this.metrics,
                            groupBy: this.groupBy,
                            id: oldTab.id,
                            name: oldTab.name,
                            model: oldTab.model,
                        });

                        this.tabs[selectedTabIndex] = tab;
                        this.selectedTab = tab;

                        this.save();
                    }
                }
            }

            addNewTab() {
                return this.createNewTab();
            }

            duplicateTab(id: string) {
                const tabToDuplicate = this.tabs.find(tab => tab.id === id);

                if (tabToDuplicate && tabToDuplicate.model?.selected) {
                    const options: IChartPageViewConfig = {
                        hierarchyId: this.hierarchyId,
                        segmentName: this.segmentName,
                        metrics: this.metrics,
                        groupBy: this.groupBy,
                    };

                    const funnelNodes =
                        tabToDuplicate.model?.funnel?.nodes?.map(node => deepStripAngularProperties(node)) || [];
                    const funnel = {
                        nodes: funnelNodes,
                        selected: tabToDuplicate.model?.funnel?.selected
                            ? deepStripAngularProperties(tabToDuplicate.model?.funnel?.selected)
                            : {},
                    };
                    const selected = tabToDuplicate.model?.selected
                        ? deepStripAngularProperties(tabToDuplicate.model?.selected)
                        : tabToDuplicate.model?.selected;

                    const tab = new ChartPageView({
                        ...options,
                        id: Utils.uuid(),
                        model: {
                            funnel,
                            selected,
                        },
                        name: tabToDuplicate.name + ' (copy)',
                    });
                    this.selectedTab = tab;
                    this.tabs.push(tab);

                    this.save();
                }
            }

            deleteTab(id: string) {
                const tabToDeleteIndex = this.tabs.findIndex(tab => tab.id === id);

                if (tabToDeleteIndex > -1) {
                    this.tabs = Utils.Array.removeAt(this.tabs, tabToDeleteIndex);
                    if (tabToDeleteIndex === 0) {
                        if (this.tabs.length === 0) {
                            this.createNewTab();
                        } else {
                            this.selectTab(this.tabs[0].id);
                        }
                        return;
                    }

                    this.selectTab(this.tabs[tabToDeleteIndex - 1].id);
                }
            }

            reorderTabs(oldIndex: number, newIndex: number) {
                this.tabs = Utils.Array.move(this.tabs, oldIndex, newIndex);
                this.save();
            }

            updateSegmentName(segmentName: string) {
                this.segmentName = segmentName;
                this.selectedTab?.setSegmentName(this.segmentName);
            }

            save() {
                const selectedTabId = this.selectedTab ? this.selectedTab.id : this.tabs[0].id;
                void ChartViewTabService.saveTabs(selectedTabId, this.tabs);
            }
        }

        return ChartPageTabs;
    },
];

export type IChartPageTabsFactory = AngularInjected<typeof ChartPageTabsFactory>;
export type IChartPageTabs = InstanceType<IChartPageTabsFactory>;

export const StoreControllerFactory = () => [
    '$scope',
    '$rootScope',
    'ChartPageTabs',
    function StoreController(
        $scope: angular.IScope & {
            query: IQuery;
            label: string;
            view: IChartPageTabs;
            hideActionsPanel: boolean;
            actions: {
                onTogglePanelClick: () => void;
                removed: (id: string) => void;
                duplicated: (id: string) => void;
                dragged: (oldIndex: number, newIndex: number) => void;
                addNewTab: () => void;
                selectTab: (value: string) => void;
                save: () => void;
            };
        },
        $rootScope: DashboardRootScope,
        ChartPageTabsFactory: IChartPageTabsFactory,
    ) {
        $scope.query = {};
        $scope.hideActionsPanel = false;

        let selectedHierarchyId = $rootScope.hierarchySelectModel?.view?.selected?.id;
        let segmentName = $rootScope.smartGroupsModel?.view?.groups?.view?.selected?.model?.name || '';

        const init = (hierarchyId: string | undefined) => {
            $scope.view = new ChartPageTabsFactory({ hierarchyId, segmentName });
        };

        $scope.actions = {
            onTogglePanelClick: () => ($scope.hideActionsPanel = !$scope.hideActionsPanel),
            removed: (id: string) => $scope.view?.deleteTab(id),
            duplicated: (id: string) => $scope.view?.duplicateTab(id),
            dragged: (oldIndex: number, newIndex: number) => $scope.view?.reorderTabs(oldIndex, newIndex),
            addNewTab: () => {
                $scope.view?.addNewTab();
                Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_CHART);
            },
            selectTab: (id: string) => {
                $scope.view?.selectTab(id);
            },
            save: () => $scope.view?.save(),
        };

        init(selectedHierarchyId);

        const unWatchSegmentName = $rootScope.$watch(
            'smartGroupsModel.view.groups.view.selected.model',
            (selectedModel: SmartGroupViewModel) => {
                segmentName = selectedModel?.name || '';
                $scope.view?.updateSegmentName(segmentName);
            },
        );

        const unWatchQueryRefresh = $rootScope.$on('query.refresh', () => init(selectedHierarchyId));
        const unWatchHierarchySwitch = $rootScope.$watch(
            'hierarchySelectModel.view.selected.id',
            (id: string | undefined) => {
                if (id && selectedHierarchyId !== id) {
                    selectedHierarchyId = id;
                    init(selectedHierarchyId);
                }
            },
        );

        $scope.$on('$destroy', () => {
            unWatchSegmentName();
            unWatchQueryRefresh();
            unWatchHierarchySwitch();
        });
    },
];
