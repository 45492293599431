import _ from 'lodash';

export interface ICurrency {
    id: string;
    symbol: string;
    label: string;
    shortLabel: string;
}

const STANDARD_CURRENCIES: Record<string, ICurrency> = {
    usd: { id: 'usd', symbol: '$', shortLabel: 'USD $', label: '($ USD) US Dollar' },
    gbp: { id: 'gbp', symbol: '£', shortLabel: 'GBP £', label: '(£ GBP) British Pound' },
    cad: { id: 'cad', symbol: '$', shortLabel: 'CAD $', label: '($ CAD) Canadian Dollar' },
    aud: { id: 'aud', symbol: '$', shortLabel: 'AUD $', label: '($ AUD) Australian Dollar' },
    jpy: { id: 'jpy', symbol: '¥', shortLabel: 'YEN ¥', label: '(¥ YEN) Japanese Yen' },
    cny: { id: 'cny', symbol: '¥', shortLabel: 'CNY ¥', label: '(¥ CNY) Chinese Yuan' },
    krw: { id: 'krw', symbol: '₩', shortLabel: 'Won ₩', label: '(₩) South Korean Won' },
    eur: { id: 'eur', symbol: '€', shortLabel: 'EUR €', label: '(€ EUR) Euro' },
    trl: { id: 'trl', symbol: '₺', shortLabel: 'TRL ₺', label: '(₺ TRL) Turkish Lira' },
    btc: { id: 'btc', symbol: '₿', shortLabel: 'BTC ₿', label: '(₿ BTC) Bitcoin' },
    php: { id: 'php', symbol: '₱', shortLabel: 'PHP ₱', label: '(₱) Philippine Peso' },
    fjd: { id: 'fjd', symbol: '$', shortLabel: 'FJD $', label: '($ FJD) Fijian Dollar' },
    myr: { id: 'myr', symbol: 'RM', shortLabel: 'MYR RM', label: '(RM) Malaysian Ringgit' },
    sek: { id: 'sek', symbol: 'kr', shortLabel: 'SEK kr', label: '(kr) Swedish Krona' },
    brl: { id: 'brl', symbol: 'R$', shortLabel: 'BRL R$', label: '(R$) Brazilian Real' },
    twd: { id: 'twd', symbol: 'NT$', shortLabel: 'TWD NT$', label: '(NT$) New Taiwan Dollar' },
    aed: {
        id: 'aed',
        symbol: '\u062F\u002E\u0625',
        shortLabel: 'AED \u062F\u002E\u0625',
        label: '(\u062F\u002E\u0625) United Arab Emirates Dirham',
    },
};

// Adding "reforecast" flavors for MK
const mkReforecastCurrencies = Object.values(STANDARD_CURRENCIES).map(currency => ({
    ...currency,
    id: `mk-reforecast-${currency.id}`,
    shortLabel: `${currency.shortLabel} (R)`,
    label: `${currency.label} - Reforecast`
}));

const CURRENCIES = {
    ...STANDARD_CURRENCIES,
    ...Object.fromEntries(mkReforecastCurrencies.map(x => [x.id, x]))
}

export const getCurrencies = () => _.cloneDeep(CURRENCIES);
