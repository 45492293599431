import _ from 'lodash'
import * as Auth from '../../lib/auth'

module = angular.module '42.directives.charts.directives', []

module.directive 'chartPicker', ->
    restrict: 'E'
    scope:
        selected: '='
        enabled: '='
    replace: true
    template: \
    """
    <article class="chart-picker">
        <ul>
            <li ng-repeat="type in chartTypes"
                ng-click="select(type)"
                ng-class="{active:isSelected(type)}"
            > <i class='icon-chart-{{type}}'></i> </li>
        </ul>
    </article>
    """
    link: (scope) ->
        scope.chartTypes = ['bar', 'pie']
        scope.isSelected = (type) ->
            return false if not scope.enabled
            return scope.selected is type
        scope.select = (type) ->
            scope.selected = type


module.directive 'echartsWithQuery', ($rootScope, EchartsWithQueryViewModel) ->
    restrict: "E"
    replace: false
    scope:
        chart: '='
    template: """
    <div class="card echart-with-query" ng-class="{loading: chart.isLoading || model.isLoading !== false }">
        <header>
            <echarts-card-title model="model.title"></echarts-card-title>
            <div class="chart-options-container">
                <div
                    class="chart-expanded-theme"
                    ng-if="false && chart.type === 'pie'"
                    ng-class="{ 'active': expandedTheme }"
                    ng-click="toggleTheme()">
                    <div class="enable-legend-button">%</div>
                </div>
                <div class="separator" ng-if="false && chart.type === 'pie'"></div>
                <chart-picker ng-if="!isMap" enabled="!model.isBlank" selected="chart.type"></chart-picker>
            </div>
        </header>
        <div class="blank" ng-if="model.isBlank">No Data</div>
        <div ng-if="model.isError && !model.isLoading" class="message-container">
            <div class="message">
                <i class="message-icon icon-attention"></i>
                <span class="message-summary">Data could not be loaded</span>
            </div>
        </div>

        <echarts-custom options="model.options"></echart-custom>
    </div>
    """
    link: (scope)  ->
        scope.isMap = false
        scope.expandedTheme = false

        scope.toggleTheme = ->
            scope.expandedTheme = not scope.expandedTheme
            scope.model.switchTheme(scope.expandedTheme)

        updateModel = _.debounce (->
            return if not scope.chart or scope.chart.isLoading
            scope.model = new EchartsWithQueryViewModel(_.cloneDeep(scope.chart), $rootScope.query)
            scope.isMap = scope.model.getType() is 'map'
        ), 100

        updateType = (type) ->
            return updateModel() if not scope.model
            scope.model.setType(type)

        scope.$watch 'chart.metrics', updateModel
        scope.$watch 'chart.type', updateType



module.directive 'echartsWithQueryGrid', () ->
    restrict: "E"
    replace: true
    scope:
        charts: '='
        selectedChart: '='
    template: """
        <div class="echarts-grid">
            <echarts-with-query
                ng-repeat="chart in charts track by $index"
                class="echarts-item"
                chart="chart">
            </echarts-with-query>
        </div>
    """
    link: (scope)  ->

        updateChart = ->
            return if not scope.charts or not scope.selectedChart
            scope.charts.forEach((c) -> c.metrics = [scope.selectedChart.metricId])

        scope.$watch 'selectedChart', ->
            updateChart()

        scope.$watch 'charts', ->
            updateChart()


# This is the Overview page chart
module.directive "echartsWithSelectedQuery", ($rootScope, EchartsWithQueryViewModel) ->
    restrict: "E"
    replace: true
    scope:
        chart: '='
    template: """
    <div class="card overview-chart echarts-item-single"
        ng-class="{loading: !model || model.isLoading, empty:model.isBlank, error:model.isError}">
        <header>
            <echarts-card-title model="model.title"></echarts-card-title>
            <bucket-picker selected="bucket"></bucket-picker>
        </header>
        <main>
            <div ng-if="model.isError" class="message-container">
                <div class="message">
                    <i class="message-icon icon-attention"></i>
                    <span class="message-summary">Data could not be loaded</span>
                </div>
            </div>
            <echarts-custom ng-if="!model.isBlank" options="model.options"></echart-custom>
        </main>
    </div>
    """
    link: (scope)  ->
        createModel = ->
            # FIXME: [DEV-1811] Hardcoded metrics
            isBudgetMetric = (scope.chart.metricId in ["budget", "store_budget", "plan", "latest_estimate", "gross_sales_rof", "gross_sales_plan"])
            properties = ["calendar.#{scope.bucket.id}", "calendar.year"]

            baseModel =
                type: 'line'
                properties: properties
                metrics: do ->
                    ty = scope.chart.metricId
                    ly = do ->
                        return "profit" if ty is "budget_profit"
                        # I could not find a better way to do this without making changes to the model
                        return "net_sales_without_taxes" if isBudgetMetric and Auth.isAllsaintsOrganizationId($rootScope.organizationId)
                        return "gross_sales" if isBudgetMetric and _.includes(scope.chart.metricId, 'gross_sales')
                        return "net_sales" if isBudgetMetric
                        return "growth_#{ty}_prev"
                    return [ty, ly]

            if isBudgetMetric
                baseModel.overrides =
                    lineChart:
                        timeRangeAsHeader: true
                        displayTitle: true
                        colors: [
                            '#A5A3FF'
                            '#5DA5DA'
                        ],
                        bucket: scope.bucket?.id

            baseModel.overrides ?= { lineChart: {} }
            baseModel.overrides.lineChart.bucket = scope.bucket.id

            return baseModel

        updateModel = _.debounce (->
            return if not scope.chart or not scope.bucket
            query = _.cloneDeep($rootScope.query)
            scope.model = new EchartsWithQueryViewModel(createModel(), query)
        ), 100

        scope.$watch 'chart', updateModel
        scope.$watch 'bucket', updateModel


module.directive 'echartsCardTitle', ->
    restrict: 'E'
    replace: true
    scope:
        model: '='
    template: \
    """
    <h1 class="echarts-title card-title">
        <div ng-if="view.override">
            <span>{{ model }}</span>
        </div>
        <div ng-if="!view.override">
            <span class="metric" ng-repeat="x in model.metrics track by x.id">{{ x.label }}</span>
            <span ng-show="model.metrics.length > 0 && model.properties.length > 0">by</span>
            <span class="metric" ng-repeat="x in model.properties track by x.id">{{ x.label }}</span>
        </div>
    </h1>
    """
    link: (scope) ->
        scope.view = {}
        scope.$watch 'model', (model) -> scope.view.override = typeof model is 'string'
