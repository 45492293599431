import _ from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'
import * as Analytics from '../../lib/analytics'
import * as AuthServiceAPI from '../../lib/auth'
import { GridItemCellRendererFactory, GridInfoCellRendererFactory } from './components/grid-cell-renderer'
import ToggleTextModule from './components/toggle-text'

module = angular.module '42.controllers.items', [ToggleTextModule.name]
module.config ($routeProvider, ROUTES, CONFIG) ->
    override = _.pick(CONFIG.routes?.grid or CONFIG.routes?.items or {}, 'label', 'url')
    route = {...ROUTES.grid, ...override}
    $routeProvider.when(route.oldUrl, {redirectTo:route.url}) if route.oldUrl
    $routeProvider.when(route.url, route)


module.controller 'ItemsController', ($q, $location, $rootScope, $scope, promiseTracker, ItemViewState, AdsConfigService) ->
    $scope.itemViewModel = {}
    $scope.loaded = false

    deferred = $q.defer()
    $scope.viewItemsPromiseTracker = promiseTracker()
    $scope.viewItemsPromiseTracker.addPromise(deferred.promise)

    $scope.loaded = true
    watchers = []
    $scope.model = null

    platform = $location.search().platform
    $location.search('platform', null) if platform

    $scope.$on '$destroy', \
    $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        watchers?.forEach (x) -> x()
        watchers = []
        watchers.push $rootScope.$watch 'hierarchyModel.selected', (x) ->
            adsConfigPromise = $q.when(undefined)
            if platform
                adsConfigPromise = AdsConfigService.fetch().then((config) ->
                    kpis = config.platforms[platform].kpis
                    platform = null
                    return kpis
                )

            promise = \
                adsConfigPromise.then (metrics) ->
                    ItemViewState.fetch(metrics).then ({actionsModel}) ->
                        deferred?.resolve()
                        deferred = null
                        $scope.model = actionsModel
                    .catch (error) ->
                        console.error("Items page state fetch error:", error)
                        deferred?.reject(error)
                    $scope.viewItemsPromiseTracker.addPromise(promise)

    $scope.$on '$destroy', ->
        watchers?.forEach (x) -> x()
        watchers = []


module.service 'ItemGridQuery', (QueryServiceAPI) ->
    fetch: (query) ->
        query = _.cloneDeep(query or {})
        throw new Error("Missing required `query.options.groupBy` property.") if not query.options?.groupBy
        return QueryServiceAPI().then (api) -> api.query.topItems(query)


module.constant 'METRIC_RESTRICTIONS',
    improved_aps:
        tables: ['transaction_items']
    sellthru_percentage:
        tables: ['transaction_items']


module.service 'ItemActionsModelState', ($q, Utils, CONFIG, ItemActionModelStateAPI, Hierarchy, HourProperty, QueryMetrics) ->

    fetchState: (metrics) ->
        ItemActionModelStateAPI.get()
        .then (state) ->
            return state if _.isArray(state?.available)
            return {selected:state?.id, available:[state]}
        .then (state) =>
            state.available = _.compact(state.available)
            return state if state.available.length > 0 and not metrics
            return @createState().then (x) ->
                if state.available.length is 0
                    x.selected.metrics = metrics if metrics
                    state.available.push(x)
                else
                    x.selected.metrics = metrics
                    state.available.unshift(x)
                    state.selected = state.available[0]

                return state
        .catch (error) ->
            console.error "Could not load item action model state:"
            console.error(error)
            return null

    fetchHierarchy: ->
        $q.all([
            Hierarchy.fetch()
            HourProperty.fetch()
        ]).then ([hierarchy, hourProperty]) ->
            hierarchy.pebbles.push(hourProperty) if hourProperty
            return hierarchy

    fetchMetrics: -> QueryMetrics.fetch().then (metrics) ->
        metrics.forEach (metric) ->
            # Frye Request: We round up all percentage cells.
            if metric.cellFilter and metric.cellFilter.indexOf('percent:') is 0 and metric.cellFilter.split(':').length is 3
                metric.cellFilter = "#{metric.cellFilter}:0"
        return metrics

    fetchValues: -> $q.all([@fetchHierarchy(), @fetchMetrics(), AuthServiceAPI.getOrganization()]).then ([{pebbles, items, stores}, metrics, organization]) ->
        storesLabel = do ->
            # FIXME: Make this configurable somehow, maybe take value from filter label?
            return 'Customers' if _.includes(organization, 'incipio')
            return 'Stores'
        hierarchy = do ->
            items.forEach  (x) -> x.group = 'Items'
            stores.forEach (x) -> x.group = storesLabel
            groupBy:      pebbles
            itemsGroupBy: items.concat(stores)
        hierarchyIndex = Object.keys(hierarchy).reduce ((result, key) ->
            result[key] = {}
            hierarchy[key].forEach (x) -> result[key][x.id] = x
            return result
        ), {}

        itemsSortBy = _.compact metrics.map (metric) ->
            return if metric.field.indexOf('growth') isnt -1
            return if not metric.headerGroup
            id:    metric.field
            group: metric.category ? 'Uncategorized'
            label: do ->
                label = _.compact([metric.headerGroup, metric.headerName]).join(" ")
                return (label or "").trim()

        hierarchyIndex: hierarchyIndex
        metrics: metrics
        metricsByField: _.keyBy(metrics, 'field')
        groupBy: hierarchy.groupBy
        itemsGroupBy: hierarchy.itemsGroupBy
        itemsLimitBy: [5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250]
        itemsSortBy: itemsSortBy
        itemsSortOrder: [
            { id:  1, label: 'ASC' }
            { id: -1, label: 'DESC' }
        ]

    save: (model) ->
        states = @serializeStates(model)
        ItemActionModelStateAPI.put(states).catch (error) ->
            console.error("Could not save item action model state:")
            console.error(error)

    serializeStates: (model) ->
        selected:  model.selected?.id
        available: model.available.map (x) => @serializeState(x)

    serializeState: (model) ->
        views =
            panel: model.views.panel.serialize()
            images: model.views.images
        selected =
            metrics:         model.selected.metrics
            groupBy:         model.selected.groupBy.id
            itemsGroupBy:    model.selected.itemsGroupBy.id
            itemsSortBy:     model.selected.itemsSortBy.id
            itemsLimitBy:    model.selected.itemsLimitBy
            itemsSortOrder:  model.selected.itemsSortOrder
        return {id:model.id, name:model.name, views, selected}

    fetch: (metrics) -> $q.all([@fetchValues(), @fetchState(metrics)]).then ([values, state]) =>
        state.available = state.available.map (x) => @normalizeState(values, x)
        state.selected = _.find state.available, (x) -> x.id is state.selected
        state.selected ?= state.available[0]
        return state

    createState: -> @fetchValues().then (values) =>
        return @normalizeState(values, {})

    duplicate: (item) ->
        @fetchValues().then (values) =>
            newItem = @serializeState(item)
            newItem.id = undefined
            return @normalizeState(values, newItem)

    normalizeState: (values, state) ->
        state = {selected:state} if not state?.views

        state ?= {}
        state.selected ?= {}
        state.views ?= {}
        state.views.panel ?= {}
        state.views.panel.active ?= true
        state.views.images ?= true
        delete state.views.metrics

        ignoreError = (fn) ->
            try
                return fn()
            catch error
                console.error(error)
                return null

        id: state.id or Utils.uuid()
        name: state.name or "New View"

        views:
            metrics: active: false
            panel: active: state.views.panel.active
            images: state.views.images

        values: values

        selected:
            metrics: ignoreError ->
                DEFAULT_METRICS = [
                    "demand_gross_sales",
                    "demand_net_sales",
                    "demand_gross_markdown_value",
                    "demand_gross_sales_margin",
                    "on_hands_units",
                    "demand_sellthru_percentage"
                ]
                state.selected.metrics ?= CONFIG.defaults?.items?.metrics or DEFAULT_METRICS
                state.selected.metrics = (state.selected.metrics or []).map (x) -> x.field or x
                availableMetrics = values.metrics.map (x) -> x.field
                return state.selected.metrics.filter (x) -> x in availableMetrics
            groupBy: ignoreError ->
                state.selected.groupBy ?= CONFIG.defaults?.items?.groupBy
                state.selected.groupBy = state.selected.groupBy?.id or state.selected.groupBy
                return values.groupBy[0] if not state.selected.groupBy
                return values.hierarchyIndex.groupBy[state.selected.groupBy]
            itemsGroupBy: ignoreError ->
                state.selected.itemsGroupBy ?= CONFIG.defaults?.items?.itemsGroupBy
                state.selected.itemsGroupBy = state.selected.itemsGroupBy?.id or state.selected.itemsGroupBy
                return values.hierarchyIndex.itemsGroupBy[state.selected.itemsGroupBy]
            itemsSortBy: ignoreError ->
                state.selected.itemsSortBy ?= CONFIG.defaults?.items?.itemsSortBy
                state.selected.itemsSortBy = state.selected.itemsSortBy?.id or state.selected.itemsSortBy
                return _.find values.itemsSortBy, (x) -> state.selected.itemsSortBy is x.id
            itemsLimitBy: ignoreError ->
                state.selected.itemsLimitBy ?= CONFIG.defaults?.items?.itemsLimitBy
                return state.selected.itemsLimitBy if state.selected.itemsLimitBy in values.itemsLimitBy
                return 10
            itemsSortOrder: ignoreError ->
                value = state.selected.itemsSortOrder
                return value if [-1, 1].includes(parseInt(value))
                return -1


module.service 'ItemActionModelStateAPI', ($q, $rootScope, Utils, StorageAPI) ->

    getKey = -> AuthServiceAPI.getOrganization().then (organization) ->
        prefix = "items.action-model-state"
         # HACK: We need to migrate user configs of all orgs.. doing it for Ippolita Wholesale in the meantime
         #       since they are complaining about hierarchy overwriting their views.
        return "#{prefix}-v2" if not _.startsWith(organization, 'ippolita_wholesale')
        hierarchyId = $rootScope.hierarchySelectModel?.view?.selected?.id
        prefix = "#{prefix}-v3"
        return "#{prefix}.#{hierarchyId}" if hierarchyId
        return prefix

    getStorageAPI = ->
        return getKey().then((key) -> StorageAPI(key))

    get: ->
        return getStorageAPI().then (api) -> api.get()

    put: (data) ->
        data = Utils.copy(data)
        return getStorageAPI().then (api) -> api.put(data)


module.service 'ItemViewState', (ItemActionModelList, ItemActionsModelState) ->
    fetch: (metrics) ->
        ItemActionsModelState.fetch(metrics).then (x) ->
            actionsModel: new ItemActionModelList(x)


module.directive 'itemView', () ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="view view-items view-items-with-images" >
        <header>
            <tabs-with-menu
                tabs="model.available"
                selected="model.selected"
                added="addNewView"
                removed="model.remove"
                dragged="model.reorder"
                duplicated="model.duplicate">
            </tabs-with-menu>
        </header>
        <main>
            <item-view-container model="model"></item-view-container>
        </main>
    </article>
    """
    link: (scope) ->
        scope.addNewView = ->
            scope.model.add()
            Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_GRID)



module.directive 'itemViewContainer', ($q, $timeout, ITEM_EXPORT_OPTIONS, QueryServiceExport, ItemGridQuery, OutsideElementClick) ->
    restrict: 'E'
    scope:
        actionsModelList: '=model'
    replace: true
    template: \
    """
    <article class="view-container view-items-container view-items-with-images-container notransition" ng-class="{hide:actionsModel && !actionsModel.views.panel.active, initializing:!initialized}">
        <header class="action-header">
            <item-actions-panel class="header-actions-panel" model="actionsModel"></item-actions-panel>
        </header>
        <main>
            <button class="button-toggle-actions-panel button-toggle-actions-panel-hide" ng-click="togglePanel()">
                <span>Hide Panel</span>
                <i class="icon-up-open"></i>
            </button>
            <article class="grid-bar">
                <item-natural-language-query model="actionsModel"></item-natural-language-query>
                <button-export on-click="export()" text="exportButtonText" ng-if="pageCount && pageCount > 0"></button-export>
                <button class="button-toggle-actions-panel button-toggle-actions-panel-show" ng-click="togglePanel()">
                    <span>Show Panel</span>
                    <i class="icon-down-open"></i>
                </button>
            </article>
            <item-grid model="actionsModel" page-count="pageCount"></item-grid>
        </main>
    </article>
    """
    link: (scope, element) ->
        $element = $(element)
        $header = $element.find('.action-header')
        $headerActionsPanel = $element.find('.header-actions-panel')
        headerActionsPanelHeight = 0

        OutsideElementClick scope, $element.find('.ui-tabs, .ui-tab'), ->
            scope.actionsModelList.toggleOffAllEditModes()

        scope.initialized = false
        clearIntervalTimeoutId = 0

        updatePanel = (active = true) ->
            headerActionsPanelHeight = do ->
                return ($headerActionsPanel.height() + 6) if $headerActionsPanel.height() isnt 0
                return headerActionsPanelHeight

            height = do ->
                return "#{headerActionsPanelHeight}" if active
                return "0"

            $header.css
                height: height

            if height is "0"
                clearTimeout(clearIntervalTimeoutId)
                $header.addClass('hidden')
            else
                # Give time for the animation
                clearIntervalTimeoutId = setTimeout((-> $header.removeClass('hidden')), 500)


            return

        transitionEnableTimeout = null
        transitionEnableTimeoutCB = () ->
            $timeout.cancel(transitionEnableTimeout) if transitionEnableTimeout
            $element[0].classList.remove('notransition')
            transitionEnableTimeout = $timeout((-> $element[0].classList.add('notransition')), 2000)

        scope.togglePanel = ->
            transitionEnableTimeoutCB?()
            scope.actionsModel?.views.panel.toggle()
            scope.actionsModel?.save()
            return

        scope.export = ->
            Analytics.track(Analytics.EVENTS.USER_EXPORTED_PAGE_GRID)
            if scope.pageCount >= ITEM_EXPORT_OPTIONS.maxPages
                alert \
                """
                Sorry, but you're trying to export too much data...
                This would generate a #{scope.pageCount} page PDF!

                Use the filters to reduce the data size, and try the export again.
                """
                return $q.reject()
            scope.actionsModel.toExportQuery()
            .then((query) -> ItemGridQuery.fetch(query))
            .then(QueryServiceExport.downloadAs('items-export.pdf'))

        scope.$watch 'pageCount', (pageCount) ->
            scope.exportButtonText = do ->
                return "export" if _.isUndefined(pageCount)
                return "export (#{pageCount} page)" if pageCount is 1
                return "export (#{pageCount} pages)"

        scope.$watch 'actionsModel.selected.itemsGroupBy.id', ->
            scope.actionsModel?.updateExtraItemInfo()

        scope.$watch 'actionsModel.views.panel.active', (active) ->
            $timeout (->
                updatePanel(active)
                $timeout (-> scope.initialized = true), 0
            ), 0

        scope.$watch 'actionsModelList.selected', (model) ->
            return if not model
            scope.actionsModel = model
            scope.actionsModelList.toggleOffAllEditModes(model)

        resizeObserver = new ResizeObserver (-> updatePanel(scope.actionsModel.views.panel.active))
        resizeObserver.observe($element[0])
        scope.$on '$destroy', -> resizeObserver.disconnect()


module.directive 'itemActionsPanel', ($rootScope, OutsideElementClick) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="item-actions-panel">

        <!-- Selector: Group By -->
        <article class="group-by-select">
            <span class="group-by-label">Group By</span>
            <ul>
                <li class="ui-pellet"
                    ng-repeat="property in model.values.groupBy"
                    ng-click="model.selected.groupBy = property"
                    ng-class="{selected:model.selected.groupBy.id == property.id}">
                    <span>{{ property.label }}</span>
                </li>
            </ul>
        </article>

        <section class="right">

            <section class="row">

                <!-- Selector: Display By -->
                <article class="model-select model-select-display-by">
                    <span class="label">Display By</span>
                    <span class="selected select-section">
                        <span class="property">
                            <span class="property-label">{{ model.selected.itemsGroupBy.label }}</span>
                            <i class="icon-down-open-mini"></i>
                        </span>
                    </span>
                    <select ng-options="property as (property.label) group by property.group for property in model.values.itemsGroupBy"
                            ng-model="model.selected.itemsGroupBy"></select>
                </article>

                <!-- Selector: Sort By -->

                <article class="model-select model-select-sort-by">
                    <div class="model-select-sort-by-header">
                        <div class="model-select-sort-by-header-label">Sort By</div>
                        <toggle-text
                            ng-if="sortToggleEnabled && model.selected.itemsSortOrder"
                            model="model.values.itemsSortOrder"
                            selected="model.selected.itemsSortOrder"
                            on-click="sortOrderClick">
                        </toggle-text>
                    </div>
                    <div class="select-section">
                        <span class="selected">
                            <span class="property">
                                <span class="property-label">{{ model.selected.itemsSortBy.label }}</span>
                                <i class="icon-down-open-mini"></i>
                            </span>
                        </span>
                        <select ng-options="property as (property.label) group by (property.group) for property in model.values.itemsSortBy"
                                ng-model="model.selected.itemsSortBy"></select>
                    </div>
                </article>

                <!-- Selector: Items Per Row -->
                <article class="model-select model-select-small model-select-limit">
                    <span class="label">Limit</span>
                    <span class="selected">
                        <span class="property">
                            <span class="property-label">{{ model.selected.itemsLimitBy }}</span>
                            <i class="icon-down-open-mini"></i>
                        </span>
                    </span>
                    <select ng-options="count for count in model.values.itemsLimitBy"
                            ng-model="model.selected.itemsLimitBy"></select>
                </article>

                <!-- Selector: Metrics to Display -->
                <ui-metric-selector-tree ng-if="metricSelectModel" model="metricSelectModel"></ui-metric-selector-tree>
            </section>

            <section class="row">
                <button class="button-toggle-images" ng-click="model.views.images = !model.views.images">
                    <i class="icon-picture"></i>
                    <span ng-if="model.views.images">Hide Images</span>
                    <span ng-if="!model.views.images">Show Images</span>
                </button>
            </section>

        </section>
    </article>
    """
    link: (scope) ->
        scope.sortToggleEnabled = Boolean($rootScope.Experiments.sortToggleInGridPage)
        scope.sortOrderClick = (id) ->
            scope.model.selected.itemsSortOrder = id

        scope.$watch 'model', ((model) ->
            return if not model
            scope.metricSelectModel =
                selected: scope.model.selected.metrics
                available: scope.model.values.metrics
                options: { addAllMetrics: false, hideCategorize: !Boolean($rootScope.Experiments.metricsCategorization) }
                onChange: (metrics) ->
                    scope.model.selected.metrics = metrics.map((x) -> x.field)
        ), true

module.constant 'ITEM_EXPORT_OPTIONS',
    itemColumnsPerPage: 10
    maxPages: 100

class ItemGridDataViewModel
    constructor: (model, ItemGridQuery) ->
        @ItemGridQuery = ItemGridQuery
        @data = null
        @init(model)

    fetch: (model) ->
        model.toQuery()
        .then((query) => @ItemGridQuery.fetch(query))
        .catch (error) ->
            console.error error
            return []

    init: (model) ->
        @fetch(model).then (data) => @data = data


module.directive 'itemGrid', ($rootScope, $timeout, ItemGridQuery, ItemGridModel, ITEM_EXPORT_OPTIONS, METRIC_RESTRICTIONS) ->
    restrict: 'E'
    scope:
        model:     '='
        pageCount: '='
    replace: true
    template: \
    """
    <article class="item-grid">
        <div class="grid-container" ng-if="grid.options">
            <div ag-grid="grid.options" class="ag-42 grid grid-new ag-theme-alpine" ng-class="{'grid-no-filter':!grid.options.enableFilter}"></div>
        </div>
    </article>
    """
    link: (scope, element) ->
        rowsData = undefined
        cellHeight = -1
        selectedMetrics = []

        scope.grid = new ItemGridModel()
        scope.pageCount = undefined

        restrictItemsSortBy = ->
            selected = scope.model.selected
            restriction = METRIC_RESTRICTIONS[selected.itemsSortBy.id]
            return if not restriction
            if _.includes(restriction.tables, selected.groupBy.table) or _.includes(restriction.tables, selected.itemsGroupBy.table)
                selected.itemsSortBy = scope.model.values.itemsSortBy[0]

        getRowsPerPage = (metrics) ->
            # these variables are from topItems in the query service.
            # this is an estimate, but it should be pretty close. the inaccuracy is in the fact that we don't actually take wrapping lines into account
            dpi = 72
            margin = Math.floor 0.6 * 0.393701 * dpi
            width  = (11  * dpi) - 2 * margin
            height = (8.5 * dpi) - 2 * margin

            headerHeight = 70
            footerHeight = 30
            tableHeaderHeight = 20
            mainHeight = height - headerHeight - footerHeight - tableHeaderHeight
            imageHeight = 50

            fontHeight = 8
            cellPadding = 4

            valueCellWidth = 60
            cellWidth = 60
            metricNameWidth = width - cellWidth * ITEM_EXPORT_OPTIONS.itemColumnsPerPage - valueCellWidth

            getTextWidth = do ->
                ctx = $('<canvas>')[0].getContext('2d')
                ctx.font = '8px Helvetica'
                return (text) -> ctx.measureText(text).width

            metricsHeight = _.sumBy metrics, (metric) ->
                (cellPadding + fontHeight) * Math.ceil(getTextWidth "#{metric.headerGroup} #{metric.headerName}") / (metricNameWidth - cellPadding)

            associatedColumnsHeight = scope.model.selected.itemsExtraInfo.length * 15

            totalRowsHeight = imageHeight + metricsHeight + associatedColumnsHeight

            return mainHeight / totalRowsHeight


        getPageCountEstimate = (data) ->
            return 0 if data.length is 0
            rowsPerPage = getRowsPerPage(selectedMetrics)
            rowPages = Math.ceil(data.length / rowsPerPage)
            itemCount = _.max(data.map (x) -> x.items.length)
            itemPages = do ->
                splitsPerPage = Math.max(1, Math.floor(rowsPerPage / data.length))
                itemPages = Math.ceil(itemCount / ITEM_EXPORT_OPTIONS.itemColumnsPerPage)
                return Math.ceil(itemPages / splitsPerPage)
            return (itemPages * rowPages)


        unWatchGridUpdate = null
        updateGridRowHeight = (options) ->
            unWatchGridUpdate?()
            return if not element[0]

            ## Wait for the table to render columns and rows
            unWatchGridUpdate = scope.$watch((->
                return true if not scope.grid.data
                return Boolean(element[0]?.getElementsByClassName('item-grid-cell-value')[0]?.getElementsByClassName('item-info'))
            ), ((isItemInfoDisplayedVisible) ->
                if isItemInfoDisplayedVisible
                    itemInfoHeight = do ->
                        itemInfoEl = element[0]?.querySelector('.item-grid-cell-value .item-info')
                        return 115 if not itemInfoEl
                        return $(itemInfoEl).height() or 115

                    itemImageHeight = do ->
                        imagesEnabled = scope.model.views.images
                        return 75 if not imagesEnabled
                        imagesInData = (scope.grid.data || []).find((item) -> item.items?.find((rowItem) -> rowItem.item_image))
                        return 130 if imagesInData

                        ## Fallback
                        imageEl = element[0]?.querySelector('.item-grid-cell-value .item-image')
                        return 75 if not imageEl
                        return 130

                    cellHeight = itemInfoHeight + itemImageHeight
                    scope.grid.options?.api.forEachNode (rowNode) ->
                        rowNode.setRowHeight(cellHeight)

                    scope.grid.options?.api.onRowHeightChanged()
                    # wait for the grid to change row height and then remove overlay
                    if options?.hideOverlay
                        $timeout (->
                            scope.grid.options?.api?.hideOverlay()
                        ), 500
            ))


        updateWithEmptyData = ->
            rowsData = []
            scope.grid.updateAllData(scope.model, [])
            scope.grid.options?.api?.showNoRowsOverlay()
            scope.pageCount = 0

        updateColumnsAndRowsData = (data) ->
            rowsData = _.cloneDeep(data)
            scope.grid.updateAllData(scope.model, data)
            scope.grid.options?.api?.showLoadingOverlay()
            updateGridRowHeight({ hideOverlay: true })
            scope.pageCount = null

        updateCellDisplayedData = ->
            return if not scope.model.selected
            isDifferentOrder = not scope.model.selected.metrics.every((metricId, index) -> selectedMetrics[index] == metricId)
            if scope.model.selected?.metrics.length != selectedMetrics.length or isDifferentOrder
                scope.grid.updateCellRenderers(scope.model)

        updateData = (data) ->
            data ?= []
            data = do ->
                return data if Array.isArray(data)
                # https://sentry.io/organizations/42/issues/2700544907/?project=5685475&query=is%3Aunresolved
                if not _.isNil(data)
                    setTimeout(() -> throw new Error("Unexpected data format updating Data in Items Page"))

                return []

            do ->
                return updateWithEmptyData() if data.length is 0
                return updateCellDisplayedData() if _.isEqual(rowsData, data)
                return updateColumnsAndRowsData(data)

            # Do this in the next tick, because it's possibly an expensive operation
            $timeout -> scope.pageCount = (try getPageCountEstimate(data)) or 'unknown'

            selectedMetrics = _.cloneDeep(scope.model.selected.metrics)
            updateGridRowHeight({ hideOverlay: true })


        # We do a debounce here because when 'model' changes, 'model.selected' also
        # changes, and we don't want to do two refreshes simultaneously
        refresh = _.debounce (->
            return scope.grid.clear() if not scope.model
            restrictItemsSortBy()
            scope.grid.options?.api?.showLoadingOverlay()
            scope.gridDataModel = new ItemGridDataViewModel(scope.model, ItemGridQuery)
        ), 10

        scope.$watch 'gridDataModel.data', (data) ->
            return if not data
            updateData(data)

        scope.$watch 'model', ->
            return if not scope.model
            refresh()

        scope.$watch 'model.selected', (->
            return if not scope.model
            scope.model.save()
            refresh()
        ), true

        scope.$watch 'model.name', _.debounce(->
            return if not scope.model
            scope.model.save()
        , 600), true

        toggleImages = (imagesEnabled) ->
            return scope.grid.clear() if not scope.model
            restrictItemsSortBy()
            scope.grid.toggleImages(imagesEnabled)
            scope.grid.options?.api.refreshCells()
            updateGridRowHeight({ hideOverlay: false })

        scope.$watch 'model.views.images', (imagesEnabled) ->
            return if not scope.model
            scope.model.save()
            toggleImages(imagesEnabled)

        scope.$on '$destroy', \
        $rootScope.$on 'query.refresh', ->
            return if not $rootScope.initialized
            return if not scope.model
            refresh()


module.constant 'ITEM_INDEPENDENT_PROPERTIES', [
    'items.variant_option_size'
    'items.size'
    'items.variant_option_color'
    'items.color_no'
    'items.color_code'
    'items.color'
    'items.season'
    'items.season_code'
    'items.season_name'
    'items.vendor'
    'items.gender'

    'items.live_on_site'
    'items.published_at_aging_bucket'

    # marolina
    'items.current_season'
    'items.original_season'
    'items.season_status'
]


module.directive 'itemNaturalLanguageQuery', ($rootScope, Utils) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="item-natural-language-query" ng-show="model">
        Showing the
        <span class="selected" ng-if="model.selected.itemsLimitBy > 1">top {{ model.selected.itemsLimitBy }}</span>
        best
        <span class="selected" ng-if="model.selected.itemsLimitBy > 1">{{ model.selected.itemsGroupBy.plural || model.selected.itemsGroupBy.label }}</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy <= 1">{{ model.selected.itemsGroupBy.label }}</span>by
        <span class="selected">{{ model.selected.itemsSortBy.label }}</span>
        <span ng-if="model.selected.groupBy.id != 'stores.company'">for each
        <span class="selected">{{ model.selected.groupBy.label }}</span>
        </span>
    </article>
    """


module.factory 'ItemGridModel', ($filter) ->

    generateColumnDefs = (actionsModel, data) ->
        GridItemCellRenderer = GridItemCellRendererFactory($filter, actionsModel)
        GridInfoCellRenderer = GridInfoCellRendererFactory($filter, actionsModel)

        imageColumnCount = _.max(data.map (x) -> x.items.length)
        return [
            headerName: actionsModel.selected.groupBy.label
            cellRenderer: GridInfoCellRenderer
            pinned: 'left',
        ].concat _.range(imageColumnCount).map (x, index) ->
            headerName: index+1
            cellRenderer: GridItemCellRenderer

    return ->
        data: null,
        clear: ->
            @update()

        updateAllData: (actionsModel, data) ->
            @data = data
            columnDefs = do ->
                return [] if not data or data.length is 0
                return generateColumnDefs(actionsModel, data)

            @options.api.setRowData(data)
            @options.api.setColumnDefs(columnDefs)

        updateCellRenderers: (actionsModel) ->
            @options.api.getCellRendererInstances().forEach (cellRenderer) ->
                cellRenderer.update(_.cloneDeep(actionsModel.selected?.metrics))

        toggleImages: (imagesEnabled) ->
            @options.api.getCellRendererInstances().forEach (cellRenderer) ->
                cellRenderer.toggleImages?(imagesEnabled)

        options:
            defaultColDef:
                resizable: false
                suppressMovable: true
                suppressMenu: true
                sortable: false
                filter: false

            rowBuffer: 10
            rowData: []
            rowHeight: 185
            headerHeight: 35
            colWidth: 200
            sortingOrder: ['desc','asc',null]
            localeText:
                loadingOoo: ' '


module.factory 'ItemActionToggleModel', -> class ItemActionToggleModel
    constructor: ({active} = {}) ->
        @active = !!active
    toggle: ->
        @active = not @active
    serialize: ->
        return {active: @active}


module.factory 'ItemActionModelList', (ItemActionsModelState, ItemActionModel, Utils) -> class ItemActionModelList

    constructor: (state) ->
        {selected, available} = state
        @available = available.map (x) => new ItemActionModel(@save, x)
        @selected = _.find @available, (x) -> x.id is (selected.id or selected)
        @selected ?= @available[0]

    add: =>
        ItemActionsModelState.createState().then (state) =>
            model = new ItemActionModel(@save, state)
            @available.push(model)
            @selected = model
        @save()

    duplicate: (id) =>
        elementToDuplicate = @available.find (x) -> x.id is id

        ItemActionsModelState.duplicate(elementToDuplicate).then (newItem) =>
            model = new ItemActionModel(@save, newItem)
            @available.push(model)
            @selected = model

        @save()

    remove: (id) =>
        @available = @available.filter (x) -> x.id isnt id
        @selected = @available[0] or @selected
        @save()

    save: =>
        try
            return ItemActionsModelState.save(@)
        catch error
            console.error error

    reorder: (oldIndex, newIndex) =>
        @available = Utils.move(@available, oldIndex, newIndex)
        @selected = @available[newIndex]
        @save()

    toggleOffAllEditModes: (exclude) =>
        toToggleOff = if exclude then @available.filter((x) -> exclude isnt x) else @available
        toToggleOff.forEach((other) ->
            other.fillNameIfNeeded()
            other.editMode = false
            other.dropdown = false
        )


module.factory 'ItemActionModel', ($rootScope, Utils, QueryMetrics, ITEM_EXPORT_OPTIONS, ITEM_INDEPENDENT_PROPERTIES, ItemActionToggleModel) -> class ItemActionModel

    constructor: (@parentSave, state) ->
        {@values, @selected, @id, @name, views} = state
        throw new Error("Missing required `values` property.") if not @values
        @fillNameIfNeeded()
        @editMode = false
        @dropdown = false
        @selected ?= {}
        @selected.groupBy      ?= @values.groupBy?[0]
        @selected.itemsGroupBy ?= @values.itemsGroupBy?[0]
        @selected.itemsSortBy  ?= @values.itemsSortBy?[0]
        @selected.itemsLimitBy ?= 15
        @selected.itemsSortOrder ?= -1
        @views =
            metrics: new ItemActionToggleModel(views?.metrics)
            panel:   new ItemActionToggleModel(views?.panel)
            images:  _.isUndefined(views.images) or views.images
        @updateExtraItemInfo()

    fillNameIfNeeded: =>
        @name = if @name.length is 0 then "New View" else @name

    toggleTabNameEditor: ->
        @fillNameIfNeeded()
        @editMode = not @editMode

    toggleDropdown: ->
        @fillNameIfNeeded()
        @dropdown = not @dropdown

    updateExtraItemInfo: -> @selected.itemsExtraInfo = do =>
        return [] if ITEM_INDEPENDENT_PROPERTIES.includes(@selected.itemsGroupBy?.id)

        # This function defines which extra properties should be listed with the item.
        extraPropertyShouldBeShown = (property) =>
            isValidField = ['items.name', 'items.product_name', 'items.item_description'].includes(property.id)
            selected = @selected.itemsGroupBy?.id
            isNotSelected = property.id isnt selected
            return isValidField and isNotSelected

        return [] if @selected.itemsGroupBy?.table isnt 'items'
        extraItemProperties = Utils.copy(@values.itemsGroupBy or []).filter(extraPropertyShouldBeShown)
        selectionPosition = _.findIndex(@values.itemsGroupBy, (x) => @selected.itemsGroupBy?.id is x.id)
        return extraItemProperties.reduce ((result, property) =>
            propertyPosition  = _.findIndex(@values.itemsGroupBy, (x) -> property.id is x.id)
            return result if selectionPosition < propertyPosition or propertyPosition is -1
            result.push(property)
            return result
        ), []

    save: =>
        @parentSave()

    toQuery: (baseQuery = null) ->
        query = Utils.copy(baseQuery ? $rootScope.query ? {})
        query.options =
            groupBy:      @selected.groupBy.id
            itemsGroupBy: @selected.itemsGroupBy.id
            itemsSortBy:  @selected.itemsSortBy.id
            itemsLimitBy: @selected.itemsLimitBy
            itemsSortOrder: @selected.itemsSortOrder ? -1
        delete query.sort
        query = Utils.copy(query)

        QueryMetrics.fetch().then (metrics) ->
            query.options.metrics = metrics.map (metric) -> metric.field
            return query

    toExportQuery: ->
        queryExport = Utils.copy do =>
            values: @values
            selected: @selected
            options: ITEM_EXPORT_OPTIONS
        return @toQuery().then (query) ->
            query.type = "pdf"
            query.export = queryExport
            return query
