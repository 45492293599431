import _ from 'lodash';
import Utils from '../../lib/utils';

export function isTotalRow<T extends { property0?: unknown }>(row: undefined | null | T) {
    return row?.property0 === '$total';
}

export interface IMetricsGridConfigViews {
    columns?: Record<string, number>;
}

export function deserializeViews(views: IMetricsGridConfigViews = {}) {
    if (!views.columns) return {};

    const columns = (() => {
        if (!Utils.isObject(views.columns)) return {};

        const columns: Record<string, number> = {};
        for (const [columnName, value] of Object.entries(views.columns)) {
            if (typeof value === 'number') {
                columns[columnName] = value;
            }
        }

        return columns;
    })();

    return _.isEmpty(columns) ? {} : { columns };
}
