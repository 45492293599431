import _ from 'lodash'
import * as Auth from '../../lib/auth'
import { QueryServiceAPI } from '../../lib/api'
import { downloadFromURL } from '../../lib/dom/download'
import { DatabaseStatusMonitor } from '../../lib/services'
import { TimerangeFilter } from '../../lib/query/query-builder'
import { hash as hashObject } from '../../lib/utils/utils-object'
import { deepStripAngularProperties } from '../../lib/angular'
import DashboardModelsModule from '../../controllers/main/dashboard-models.coffee'
import LegacyUtilsModule from '../legacy-utils-module'
import { logError } from '../../lib/analytics'

module = angular.module '42.modules.services.query', [
    LegacyUtilsModule.name,
    DashboardModelsModule.name
]
export default module


QueryCache = do ->
    storage = {}

    get: (queryId, query) ->
        query ?= {}
        return undefined if (query.type isnt 'json')
        key = queryId + hashObject(query)
        return storage[key]

    set: (queryId, query, value) ->
        key = queryId + hashObject(query or {})
        return storage[key] = value

    del: (queryId, query) ->
        key = queryId + hashObject(query or {})
        delete storage[key]


module.service 'UserRestrictions', ($rootScope, CONFIG, Utils) ->

    getOrganizationRestrictions = ->
        filters = CONFIG.accessControl?.filters or {}
        return null if _.isEmpty(filters)
        return Utils.copy(filters)

    getUserRestrictions = ->
        filters = $rootScope.accessControl?.filters or {}
        return null if _.isEmpty(filters)
        return Utils.copy(filters)

    getRestrictions = ->
        orgRestrictions = getOrganizationRestrictions()
        userRestrictions = getUserRestrictions()
        filters = _.assign {}, userRestrictions, orgRestrictions
        return null if not filters or _.isEmpty(filters)
        return Utils.copy(filters or {})

    restrictQuery: (query) ->
        query = Utils.copy(query or {})
        restrictions = getRestrictions()
        query.restrictions = restrictions if restrictions
        return query


# Most of the work here is to convert timerange ids (ex: std) into actual timeranges,
# based on the current calendar selection
hasSentMetricFilterError = false
module.service 'QueryMetricFilters', ['$q', 'CONFIG', 'DashboardCalendarModel', ($q, CONFIG, DashboardCalendarModel) ->

    resolveMetricFilterTimerange = (calendarModel, metricFilter) ->

        return metricFilter.timerange if _.isPlainObject(metricFilter.timerange)

        {selection, comparison} = do ->
            if typeof metricFilter.timerange is 'string'
                datepicker = calendarModel.getDatepicker().copy({shouldClamp: false})
                datepicker.setAction(metricFilter.timerange)
                return datepicker.serialize()
            return {}

        return null if not selection
        selection = TimerangeFilter.fromTimerange(selection)
        comparison = TimerangeFilter.fromTimerange(comparison) if comparison
        return {selection, comparison}

    resolveMetricFilters = (calendarModel, metricFilters) ->
        return Object.entries(metricFilters).reduce(((result, [id, metricFilter]) ->
            try
                timerange = resolveMetricFilterTimerange(calendarModel, metricFilter)
                return result if not timerange
                result[id] = {...metricFilter, timerange}
                return result
            catch error
                return result if hasSentMetricFilterError
                hasSentMetricFilterError = true
                metricFilterStr = try JSON.stringify(metricFilter, null, 2)
                logError(new Error("Could not resolve metric filter:\n#{metricFilterStr} #{cause:error.message}"))
                return result
        ), {})

    fetch: -> $q.when().then ->
        metricFilters = CONFIG.kpis?.filters
        return null if not metricFilters
        return DashboardCalendarModel.fetch().then (calendarModel) ->
            return resolveMetricFilters(calendarModel, metricFilters)
]


module.factory '42.services.query.api', ['$q', 'CONFIG', 'UserRestrictions', 'QueryMetricFilters', ($q, CONFIG, UserRestrictions, QueryMetricFilters) ->
    return ({host}) -> $q.when(QueryServiceAPI.get()).then (api) ->

        # Add custom composite metric definitions from org config
        addMetricDefinitions = (query) ->
            definitions = CONFIG.kpis?.definitions
            query.definitions = {...(definitions or {}), ...query.definitions }
            return query

        # Add custom metric definitions from org config
        addCustomMetrics = (query) ->
            foundations = CONFIG.kpis?.foundations
            query.foundations = foundations if foundations
            return query

        addModifiers = (query) ->
            modifiers = {...(CONFIG.modifiers ? {}), ...query.modifiers}
            modifiers.enableFoundationPruningFromFilters = CONFIG.flags?.enableFoundationPruningFromFilters
            modifiers.bypassLegacyDemandMetrics          = CONFIG.flags?.bypassLegacyDemandMetrics
            modifiers.bypassTransfersInSellThruMetric    = CONFIG.flags?.bypassTransfersInSellThruMetric
            modifiers.timezone                           = (new Date()).getTimezoneOffset()
            return { ...query, modifiers }

        # FIXME: Remove this / generalize it... both here and at query service level...
        # Used by allsaints for real-time...
        getMaxTimestampModifier = ->
            promise = $q.when(DatabaseStatusMonitor.getStatus())
            return promise.then (status) ->
                return status?.latestTransactionTimestamp ? null

        addAllsaintsRealtimeMaxTimestampFilterHack = (query) ->
            return query if not (query.filters?.transactions?.timestamp?.$lt and query.comparison?.timestamp)
            return getMaxTimestampModifier().then (maxTimestamp) ->
                return {...query, modifiers:{...(query?.modifiers), maxTimestamp}} if maxTimestamp
                console.error("Could not get 'latestTransactionTimestamp' from database status. Can't set max timestamp.")
                return query

        prepareQuery = (query) ->
            query = _.cloneDeep({...query, filters:{...(query?.filters or {})}})
            return $q.all([
                QueryMetricFilters.fetch()
                Auth.getOrganization()
                Auth.getUser()
            ]).then ([metricFilters, organizationId, user]) ->
                query = {...query, metricFilters} if metricFilters
                query = UserRestrictions.restrictQuery(query)
                query = addModifiers(query)
                query = addMetricDefinitions(query)
                query = addCustomMetrics(query)
                query.type = do ->
                    return 'json' if _.isNil(query.type)
                    return query.type.toLowerCase()
                isAllsaints = do ->
                    allsaintsOrganizations = ['allsaints', 'allsaints-new', 'allsaints_dev', 'allsaints_wholesale', 'allsaints_dtc']
                    return allsaintsOrganizations.includes(organizationId)
                if isAllsaints
                    delete query.comparison if user.name is 'digital dashboard' # REVIEW: Why?
                    return addAllsaintsRealtimeMaxTimestampFilterHack(query, user)
                query = deepStripAngularProperties(query)
                return query

        QueryServiceAPIWrapper = ->
            getMetrics: ->
                return $q.when(api.organizations.getMetrics())

            getDescriptors: ->
                return $q.when(api.organizations.getDescriptors())

            doQuery: (queryId) -> (query, timeout) -> $q.when().then ->
                return prepareQuery(query).then (query) ->
                    doQuery = -> api.organizations.doQuery({queryId, query}, {timeout})
                    promise  = QueryCache.get(queryId, query)
                    promise ?= QueryCache.set(queryId, query, doQuery())
                    return promise.then (data) ->
                        return data if query.type is 'json' # json response
                        return {host, data} # export response
                    .then (data) ->
                        return _.cloneDeep(data)
                    .catch (error) ->
                        QueryCache.del(queryId, query)
                        throw error

        return $q.when(api.organizations.getQueryIds()).then (queries) ->
            wrapper = QueryServiceAPIWrapper()
            wrapper.query = queries.reduce ((queries, queryId) ->
                queries[queryId] = wrapper.doQuery(queryId).bind(wrapper)
                return queries
            ), {}
            return wrapper
]


module.service 'QueryServiceExport', ->
    # `host` the query service host
    # `data` is whatever the server returned.
    downloadAs: (filename) -> ({host, data}) ->
        dataType = encodeURIComponent(data.type)
        filename = encodeURIComponent(filename)
        id = encodeURIComponent(data.id)
        url = "#{host}/exports/#{dataType}/#{filename}?id=#{id}"
        return downloadFromURL({url})
